import React, {Component} from 'react'
import {Container, Row, Col} from 'reactstrap';
import CtaComponent from '../../../containers/callToAction'
import pricingHeaderImage from '../../images/pricing.jpg'

class DiscountOptions extends Component {
  render() {
    return (<div className="pricing">
      <div className="img-header">
        <h1>Discount Possibilities</h1>
      </div>
      <section>
        <Container>
          <Row>
            <Col md={12}>
            <h3>
            Air Ambulance Discounts Available Based on the Location of Our Deployed Aircraft
            </h3>
            <p>
            Patients and families can take advantage of a U.S. Air Ambulance aircraft positioned close to the patient's departure location. Rather than paying for "round trip" travel, costs are calculated on miles logged from our medical plane's current location (based on a recent air ambulance trip) to your departure location, then on to your final destination.
            </p>
            <p>
            <b>Here is how it works...</b>
            </p>
            <ol>
              <li>Standard industry practice calculates air ambulance cost based on total miles flown for each trip - from departure location to arrival location, and back to original departure location.</li>
              <li>Frequently, patients and families can take advantage of U.S. Air Ambulance aircraft that are positioned close to your patient's departure location.</li>
              <li>Rather than paying for "round trip" travel, medical flight costs are calculated on miles logged from our plane's current location (based on a recent air ambulance trip) to your departure location, then on to your final destination.</li>
              <li>The shorter the total mileage of your "piggy back" trip, the more you save, giving you the opportunity to realize significant saving on your medical flight costs.</li>
            </ol>
            </Col>
          </Row>
        </Container>
      </section>
      <CtaComponent></CtaComponent>
    </div>)
  }
}

export default DiscountOptions

import { LOCATION_CHANGE } from 'react-router-redux'
import { createReducer } from '../modules/helpers';

const initialState = {
  route: "/"
}

const navigation = createReducer(initialState, {
  [LOCATION_CHANGE](state, action) {
      return {
        ...state,
        route: action.payload.pathname
      }

  }
})

export default navigation

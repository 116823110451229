import React from 'react';
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'

import createHistory from "history/createBrowserHistory"
import * as serviceWorker from './serviceWorker';
import { configureStore } from './store'
import ScrollToTop from "./scroll.js"
// import ReactGA from 'react-ga';

import App from './containers/app'
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

const target = document.querySelector('#root')
const store = configureStore()
const history = createHistory()

history.listen((location) => {
  // ReactGA.pageview(window.location.pathname + window.location.search);
})

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </ConnectedRouter>
  </Provider>,
  target
)

serviceWorker.unregister();

import React, { Component } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import './cta.css';


class CallToAction extends Component {

  render() {
    return(
      <section className='w-100 p-5 call-to-action'>
        <h1>Have more questions? We're happy to answer them.</h1>
        <h2>Call Now For A Free, Same-Day Quote</h2>
        <div className="phone-number">
          <a className="btn" href="tel:1-800-948-1220">1-800-948-1220</a>
          <a className="secondary-button btn" href="/request-a-quote">Get a Quote</a>
        </div>
      </section>
    )
  }
}

export default CallToAction

import React, {Component} from 'react'
import {Container, Row, Col} from 'reactstrap';
import CtaComponent from '../../../containers/callToAction'
import pricingHeaderImage from '../../images/pricing.jpg'

class LowCost extends Component {
  render() {
    return (<div className="pricing">
      <div className="img-header">
        <h1>Low Cost Options</h1>
      </div>
      <section>
        <Container>
          <Row>
            <Col md={12}>
              <h3>Low Cost Options</h3>
              <br></br>
              <p>
                For patients who must be transported immediately by air ambulance service, our all-inclusive price quote will be competitive, with a low price guarantee. If you receive a lower quote from another company - for travel on the same day, to the same destination, in the same type of aircraft - we will meet or beat that price. We offer aircraft options including jet service (the fastest and most costly method of medical air transport) as well as lower-priced turboprops and twin engine aircraft. All planes are medically-equipped and safe. Aircraft often return to base empty after dropping off a patient. If you have the flexibility to schedule your flight so that you can "piggy back" on an existing flight, you may be able to save thousands of dollars on your air ambulance cost. The Patient Transport Specialists at U.S. Air Ambulance will gladly discuss cost-saving air medical transport options with you, insuring that you're offered appropriate transport at the very best price.
              </p>
              <p>
                Stable patients may qualify for medical escort on a commercial plane, medical escort via Amtrak train or via long distance ground ambulance transportation. These options can offer a savings of up to 50% over the cost of air ambulance service. For patients flying internationally and cannot sit up for take-off and landing on a commercial flight, we offer our medical escort service using an airline stretcher. The stretcher and all necessary medical equipment are placed in the rear of the plane. International commercial stretcher flights require one to two weeks notice to arrange, but can save thousands of dollars.
              </p>

              <br></br>
              <br></br>
              <h3>Low Price Guarantee on Air Ambulance Medical Transportation Services</h3>

              <br></br>
              <p>
                We are committed to helping you find the best air ambulance prices for transporting your loved one. After reviewing the patientà­¥dical information, our Patient Transport Specialists will recommend the least expensive option. We offer a Low Price Guarantee, which insures that you will receive the lowest air ambulance transport cost. Simply obtain a written quote from another licensed, insured, nationally accredited air ambulance company for a similar aircraft and medical team on the same day. If you have received a lower price, U.S. Air Ambulance will meet or beat it.
              </p>

            </Col>
          </Row>
        </Container>
      </section>
      <CtaComponent></CtaComponent>
    </div>)
  }
}

export default LowCost

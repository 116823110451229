import React, {Component} from 'react'
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

import classnames from 'classnames';
import CtaComponent from '../../containers/callToAction'
import "./faq.css"

class FAQ extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({activeTab: tab});
    }
  }
  render() {
    return (<div className="faq">
      <div className="img-header">
        <h1>FAQs</h1>
      </div>
      <section className="about-us">
        <Container>
          <Row>
            <Nav tabs="tabs">
              <NavItem>
                <NavLink className={classnames({
                    active: this.state.activeTab === '1'
                  })} onClick={() => {
                    this.toggle('1');
                  }}>
                  Basic Q&A
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({
                    active: this.state.activeTab === '2'
                  })} onClick={() => {
                    this.toggle('2');
                  }}>
                  Getting Prepared For Transport
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({
                    active: this.state.activeTab === '3'
                  })} onClick={() => {
                    this.toggle('3');
                  }}>
                  Financial
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({
                    active: this.state.activeTab === '4'
                  })} onClick={() => {
                    this.toggle('4');
                  }}>
                  Insurance
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({
                    active: this.state.activeTab === '5'
                  })} onClick={() => {
                    this.toggle('5');
                  }}>
                  Medical
                </NavLink>
              </NavItem>

            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Container>
                  <Row>
                    <Col sm="12">
                      <ListGroup flush>
                        <ListGroupItem>
                          <a name="01" id="01">01. what exactly is an air ambulance?</a>
                          <p>an air ambulance is a business class aircraft that has been specially equipped with a flight stretcher, advanced life support equipment, medications and a highly trained medical staff. u.s. air ambulance uses gulfstream, lear jet, king air and cessna planes to fly our patients from one medical facility to another.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="02" id="02">02. are you an air ambulance company?</a>
                          <p>yes, we are a licensed air ambulance operator, and we are also a patient transport company in that we provide medical escorts, airline stretcher transports for international flights, and ground and rail transports throughout the united states and europe. unlike charter companies, we focus only on medical transports.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="03" id="03">03. why are you a better company than the other air ambulances that i have called?</a>
                          <p>here are just a few reasons why u.s. air ambulance is best for your needs:
                          </p>
                          <ul>
                            <li>we are one of the largest and most experienced air ambulance companies in the world.</li>
                            <li>we have been in business since 1990 and have safely transported over 97,000 patients.</li>
                            <li>we have a 100 percent safety record.</li>
                            <li>our dedicated full time staff is available 24 hours a day, 365 days a year to help you. very few of our competitors have that accessibility and availability.</li>
                            <li>we can transport the most critical patients from neonatal to trauma and organ transplant cases.</li>
                            <li>we offer multiple options in various price ranges to accommodate your budget and will attempt to lower your costs until the very day we do your trip.</li>
                            <li>we are licensed and insured.</li>
                            <li>with u.s. air ambulance, one call does it all. we handle all the details for you.</li>
                            <li>we are a preferred provider for the u.s. department of state</li>
                          </ul>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="04" id="04">04. do you offer services that other companies don't?</a>
                          <p>yes. we are a complete medical concierge company. you can turn to us with help on finding appropriate facilities, listing a home for sale, selling or moving furniture or automobiles, locating home health care services, pet care, financial and legal advice -- just about anything you would need to help ease the transport of a patient from one location to another.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="05" id="05">05. what is your safety record?</a>
                          <p>u.s. air ambulance has a 100 percent safety record. we have the highest safety record in the international air ambulance industry.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="06" id="06">06. are you able to move my loved one? he needs medical attention.</a>
                          <p>u.s. air ambulance has the medical capability to transport the most acute patients, as well as those who need just a little assistance. since 1990, we have provided ground, rail and air transport to over 97,000 people, from infants to senior citizens. our patient transport specialists and medical staff will work with the patient's health care team to determine the most appropriate and cost-effective method of safely transporting your loved one.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="07" id="07">07. how will i know if my loved one needs an air ambulance?</a>
                          <p>if the patient requires extensive or urgent medical assistance, air ambulance is the fastest and safest method of transport. however, we offer lower cost alternatives for some stable patients. these include long-distance ground transportation by ambulance, commercial airline stretcher for international flights, and medical escorts on amtrak and commercial airlines. our patient transport specialist will explain those options to you when you call.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="08" id="08">08. when can i reach someone to discuss my needs?</a>
                          <p>u.s. air ambulance is staffed 24 hours a day, 365 days a year to assist you. call and a flight coordinator will personally answer your questions.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="09" id="09">09. is your service licensed and insured?</a>
                          <p>yes. we are fully licensed and insured. we also carry medical liability insurance of $1 million and $5 million in aircraft per occurrence and additional business insurance of $1 million per occurrence.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="10" id="10">10. where are you located?</a>
                          <p>our corporate offices are in sarasota, florida, with additional bases in tucson, arizona, ft. lauderdale, florida, and raleigh, north carolina. we constantly fly patients throughout north and south america, europe, australia, africa and asia.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="11" id="11">11. does your service fly to my locations?</a>
                          <p>u.s. air ambulance flies to all continents except antarctica. within the u.s., we travel to hundreds of airports - more than are used by commercial carriers. chances are excellent that there is an airport within minutes of your location and destination.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="12" id="12">12. who will be setting up my trip?</a>
                          <p>our entire team is dedicated to helping you from the moment you call us. your primary contact will be one of our patient transport specialists who will work with you directly. he or she will then pass on your information to a case manager, who will call all of the facilities involved to set up an itinerary for your trip. assisting him or her will be our medical director and chief flight nurse, and our flight operations manager. once the trip is scheduled, our case manager will coordinate with everyone involved, including the hospitals, doctors, nurses, ambulances, airports, our crew and medical team, and family members.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="13" id="13">13. can you explain your process?</a>
                          <p>the best way to explain our process is to walk you through a typical trip.</p>
                          <ul>
                            <li>after receiving your call, our patient transport specialist will ask you some questions so we can best understand your personal needs. that information will then relayed to a case manager.</li>
                            <li>once you are completely comfortable and prepared to use our service, the case manager will confirm the payment arrangements and then call the sending and/or receiving facilities.</li>
                            <li>the case manager gives the information to our medical director, who obtains a full medical report from the attending doctor or hospital. he then determines the type of medical personnel we need to have onboard.</li>
                            <li>once that's established, our case manager completes setting up the trip and prepares a full itinerary for the family. they will review times and other important information with you, and send you a full itinerary in writing that repeats everything that was discussed.</li>
                            <li>on the day of the transport itself, your patient transport specialist will manage the trip to ensure that everything goes smoothly and will make about 20 calls to everyone in the process. our medical team will go into the hospital to evaluate the patient and then ride to the plane with the patient in the ambulance.</li>
                            <li>the ambulance drives right up to the aircraft, where we then load the patient and any passengers into the plane and go over safety issues with everyone before taking off.</li>
                            <li>once we arrive at the receiving airport, another ambulance will be there on the runway waiting to take the crew and patient to the receiving facility.</li>
                            <li>there, we do a direct admission to the patient's bed, reconnecting the patient to the hospital's equipment (if required).</li>
                            <li>we then go over the trip report with the nurses at the hospital prior to departing.</li>
                            <li>once the nurses are satisfied and take over care of the patient, our team departs.</li>
                          </ul>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="14" id="14">14. my mom had an accident recently and we would like to bring her to our home to live with us. do you do that sort of thing?</a>
                          <p>yes. that is exactly what we do. we bring loved ones who have been in an accident or have become ill closer to home so that their families can be with them and help them recover.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="15" id="15">15. can you accompany my mother on a commercial flight?</a>
                          <p>yes, that's the purpose of our medical escort service. we will supply a registered nurse for your mother, arrange the ground transportation for you, meet the patient at her residence or the airport and check the patient in at the ticket counter. we'll then wheel her to her gate and fly first class to the destination of your choice. during the trip, our nurse will take care of all her medical and hygiene needs, as well as serve as an excellent traveling companion. some of our patients have alzheimer's and can get agitated during a flight. our nurses are trained to deal with those and other special needs.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="16" id="16">16. can you take more than one patient on a plane?</a>
                          <p>yes. some planes are outfitted with two stretchers, and sometimes one of the patients can sit because their medical needs are less critical. we can work with you and provide a competitive price for moving two patients together on the same aircraft.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="17" id="17">17. how long will the flight take?</a>
                          <p>this depends on the departure and destination cities. jets fly at speeds of 550 miles per hour, slightly faster than most commercial airlines. as an example, if flying from florida to new york, the trip is about two and a half hours. the turbo and twin engines fly at slightly slower speeds for a reduced cost.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="18" id="18">18. how fast can you get here?</a>
                          <p>since we are a 24-hour operation, we can respond immediately to your request. it typically takes about 90 minutes to call out a crew and have them prepare the aircraft for takeoff. from that point, we fly directly to the airport closest to the patient. we will keep in constant communication with you during this time so you will know our precise arrival time.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="19" id="19">19. how soon do we need to schedule the flight?</a>
                          <p>if we have a plane and crew available, we can transport your patient by air ambulance the same day that you schedule a flight. however, we like to have more time so our doctor can speak with the patient's doctor, and that sometimes takes a little time. commercial medical escorts require more time because of airline restrictions; typically expect 48 to 72 hours if oxygen is required. stretcher transports for international flights can take from seven to ten business days so that the airline can schedule maintenance to take out the seats and install a stretcher. if price is potentially an issue, the more time you can give us, the better chance we have of connecting it with another flight and saving you a substantial amount of money. when you are comfortable and ready for the flight, give us a call and we'll set it all up for you.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="20" id="20">20. how soon can i get a quote?</a>
                          <p>the minute our patient transport specialist hangs up from speaking with you, he or she will begin to work up a quote for your specific needs. in most cases, you'll hear back within 10 to 15 minutes. for some quotes including those that require information from the medical desks, or international quotes with multiple stops, it sometimes takes a bit longer. however, we will call you back with information as well as send a fax or email confirmation for your convenience.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="21" id="21">21. can i get an itinerary?</a>
                          <p>yes, as soon as you confirm a trip with us, we will quickly coordinate the timing with the family and both the sending and receiving facilities. we set up all the arrangements: ground transports, coordinating with the facilities, aircraft schedules. we'll then create a written itinerary for you that can be sent by fax, email or regular mail - whatever you choose - and then our flight coordinator will review the itinerary in detail with family members on the phone.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="22" id="22">22. can you take my mother's luggage in the ground ambulance?</a>
                          <p>yes. our nurse will arrange to have the luggage transferred by the ground ambulance to our aircraft. due to the nature of an air ambulance, space is very limited. we recommend one carry-on per person.
                          </p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="23" id="23">23. can the family ride in the ground ambulance?</a>
                          <p>one family member can ride in the front of the ambulance. because of ground ambulance rules, passengers cannot ride in the back. if there is more than one passenger, we can assist by setting up a cab for you, which will follow the ambulance right to the plane. two people can ride in the air ambulance. pets are allowed on the plane but not in the ground ambulance, so other arrangements must be made to get the pet to the airport.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="24" id="24">24. can you call me with updates throughout the course of the trip?</a>
                          <p>yes. our case managers will typically call you when we land prior to arriving at the sending hospital and prior to arrival at the receiving hospital. if you want more frequent updates, just let us know and we will be happy to fulfill your requests.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="25" id="25">25. does your crew go into the hospital?</a>
                          <p>u.s. air ambulance provides a comprehensive bedside to bedside service, which means that the ambulance will come to pick up the patient. our flight crew will be on that ambulance and will go into the facility, switch your patient onto our medical equipment, put him right onto the ambulance stretcher and then take him out to the aircraft. then we reverse the process after landing, taking the patient directly to the bedside they are assigned and connecting him to that facility's equipment. this ensures continuity of care while helping the patient feel more relaxed with only one person to solve their needs and to act as a companion.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="26" id="26">26. i have a gulfstream aircraft. can you put a medical crew and equipment on my plane?</a>
                          <p>yes, we can put our crew and equipment on your gulfstream and fly with your patient. if the patient doesn't require advanced life support and can sit in the aircraft, our team will assist the patient with basic life support equipment and hygiene functions. if the patient requires lying down during the trip, accommodations should be made to secure the patient. if equipment is required, we will work with you to ensure that proper connections for power and oxygen are in place prior to the trip.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="27" id="27">27. what kind of ground transportation do you provide?</a>
                          <p>unless otherwise directed, we include ground transportation from the sending hospital to the airport and from the airport to the receiving hospital (bedside to bedside) as part of our package of services. depending on the medical needs of the patient, we can provide advanced life support ground ambulance, wheel chair van or stretcher van. in some instances we might even use a limousine or town car upon a family's request. our nurses will travel with the patient and will bring our equipment to ensure the patient's safety. in addition, our customer service team monitors the progress of the transportation to ensure they arrive on time and that any needs are quickly met.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="28" id="28">28. do i have to set up the transportation?</a>
                          <p>most of our patients and families find it much easier to let us take care of that burden for them. all costs are included in our price so there are no hidden charges. if, however, you wish to arrange the ground transportation, we ask that you give us their contact information so on the day of the trip we can still provide the needed coordination for you.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="29" id="29">29. should i meet you at the airport or at the hospital?</a>
                          <p>you can meet us at whichever location is more convenient for you. we will wait for you. if you meet us at the hospital, you have the option of riding along in the ambulance as long as there are no pets accompanying you. if you want to meet us at the airport, we'll give you the address and phone number of the fbo so you can make those arrangements.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="30" id="30">30. what is an fbo?</a>
                          <p>fbo stands for fixed base operator. it's a refueling station for private aircraft, and is usually located on the opposite side of the runways from the main commercial terminals. the planes fly directly into and out of the location without waiting in commercial flight traffic. the ambulance pulls up right next to the airplane so the patient can be easily loaded and unloaded.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="31" id="31">31. can you fly to me prior to picking up the patient?</a>
                          <p>we can; that's not a problem. our typical trip goes directly to the patient, but if you prefer we can arrange for our plane to start at your airport and fly from there to pick up the patient. the aircraft and crew are at your disposal, so we can create a trip that is customized to your needs.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="32" id="32">32. are you a broker?</a>
                          <p>we are a licensed operator under the direction of dr. roque rodriguez, our medical director, and chris carter, our chief flight nurse, as well as full- and part-time medical, flight and maintenance crews. we staff our corporate office 24 hours a day and operate out of several other cities throughout the united states. we have safely transferred over 97,000 patients without an accident. we have excellent credentials.</p>
                        </ListGroupItem>

                        <ListGroupItem>
                          <a name="33" id="33">33. are you camts certified?</a>
                          <p>unlike the joint commission on accreditation of healthcare organizations (jcaho), which is an independent accrediting organization for hospitals, no truly independent organization for accrediting fixed wing aircraft yet exists. camts (commission of accreditation of medical transport systems) is basically a group of air ambulance companies that have tried to set standards, but they are not widely accepted. therefore, the vast majority of air ambulances, including our company, have chosen not to participate in camts.</p>
                          <p>however, we do participate in other organizations including the air medical physicians association (ampa), the north american ambulance alliance (n3a), the aerospace medical association (asma), the air and surface transport nurses association (astna), the american association of critical care nurses (aacn), the american association of respiratory care (aarc), the american college of emergency physicians (acep), the emergency nurses association (ena), the national air transportation association (nata), the national association of air medical communication specialists (naacs), the national ems pilots association (nemspa) and the national association of ems physicians (naemsp).</p>
                        </ListGroupItem>

                      </ListGroup>
                    </Col>
                  </Row>
                </Container>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <ListGroup flush>

                      <ListGroupItem>
                        <a name="34" id="34">34. what am i required to do to prepare for the trip?</a>
                        <p>the first thing to do is to decide on a receiving physician and facility. we can help you if you are uncertain about the best choices. second, decide on the trip date and the time you want the patient to be picked up. again, we can help by offering options if your travel time is flexible. we will request the following basic information: departure and arrival destinations, medical information and method of payment. u.s. air ambulance does everything else - scheduling aircraft and ground transportation, arranging for the medical team, coordinating medical facilities, managing your flights and submitting your insurance claim. we provide a worry-free transport.</p>
                        <p>if traveling by plane, you will also need a photo id for the patient and anyone traveling with him or her. if flying internationally, all passengers are required to have a passport and any applicable visas.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="35" id="35">35. in addition to the information i'll give you, what will i need to do?</a>
                        <p>once you give us the information, everything else regarding the transfer is in our hands. if you haven't yet arranged for a receiving doctor and facility, you'll need to do that. if you need assistance, we can help. we really try to take the stress out of the entire situation for you.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="36" id="36">36. how far in advance do i need to schedule the trip?</a>
                        <p>some of our clients must fly within hours and we can certainly accommodate short notice trips. however, it is best to give us at least 24 hours notice if possible. doctor consultations must sometimes be flexible to your attending doctor's needs, and more notice can sometimes substantially reduce your cost. our flight coordinators can provide the details.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="37" id="37">37. i don't have a bed yet. what do i need to do?</a>
                        <p>you'll need to decide on the facility and then a physician there who will be assigned to the patient and will coordinate with admissions to accept him or her. if you need help in doing that, we can assist.</p>
                      </ListGroupItem>

                    </ListGroup>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                    <ListGroup flush>

                      <ListGroupItem>
                        <a name="38" id="38">38. how much does your service cost?</a>
                        <p>as you might expect, the cost of medical transportation is fairly expensive. u.s. air ambulance is committed to providing the lowest price possible without compromising patient care. the pricing section on this website discusses pricing examples, rate schedules and several money-saving options for your transport.</p>
                        <p>with a quick call to 1- we can provide a no-obligation, guaranteed price quote to you.
                        </p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="39" id="39">39. is ground transportation included in your price?</a>
                        <p>yes. ground transportation and all other costs are included unless specifically omitted by customer request. we will provide you with a written quote. to accurately compare pricing, we encourage you to request written quotes from other vendors before making your decision.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="40" id="40">40. what form of payment does u.s. air ambulance accept?</a>
                        <p>we accept money orders, bank wire drafts, cashiers checks, personal checks (in some instances), cash, all major credit cards, and letters of guarantee from insurance companies.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="41" id="41">41. are all of your costs included or might there be additional charges?</a>
                        <p>all of our costs are included within our price: ambulance transport, the cost of the medical crew, all of the equipment and medications and the flight itself. any changes to costs that might occur as a result of changing patient conditions (such as an upgrade to a ground ambulance from a wheelchair van) would be made prior to a transport and with the full knowledge of the customer.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="42" id="42">42. is there a less expensive alternative than air ambulance?</a>
                        <p>there are other alternatives that are generally less expensive.
                        </p>
                        <ul>
                          <li>medical escort on amtrak. we book a special needs sleeper car and supply it with our medical personnel to assist and transport your family member by train.</li>
                          <li>long-range ground transport, in which we staff an ambulance with two drivers and medical personnel.</li>
                          <li>commercial flight with a medical escort, in which the patient and escort sit in the first class cabin of a commercial airliner. the nurse monitors the patient, administers any necessary oxygen or medications, and assists with hygiene tasks.</li>
                          <li>commercial airline stretcher service, available only for international flights. we arrange with the airline to remove six to eight seats in the rear of the aircraft and install a stretcher for you. we'll provide one or two of our nurses to accompany the patient on the flight.</li>
                          in all cases, we still provide ground transportation for you on both sides of the trip, medications, life support equipment and necessary and prescribed medications.
                        </ul>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="43" id="43">43. do you provide any discounts?</a>
                        <p>we do provide a discount with what we call our "backhaul" service. that's an industry term that simply means that if the patient is flying from tampa to st. louis, for example, and we have a trip already booked from st. louis to tampa, both patients can save because each has essentially bought a one-way trip instead of round trip. of course, this type of schedule requires some flexibility on your part, but it could reduce your costs substantially.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="44" id="44">44. are there any other ways you can reduce the cost of the flight for me?</a>
                        <p>we will explore every option with you to assure you receive the most appropriate travel for your patient's needs. for example, we can help you select from a variety of aircraft. gulfstream and learjet planes are the most expensive and the fastest. if you are not under serious time constraints, a turbo-prop or twin-engine plane would be less expensive.</p>
                        <p>it's important to remember that if you're going from a residence to a residence you'll have much more flexibility than if you're going into a nursing home or other managed care facility that may be reluctant to hold a bed more than one or two days.</p>
                        <p>of course, if the patient is critical, in trauma or scheduled for an organ transplant, the fastest trip, by jet, is really your best alternative.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="45" id="45">45. do you know of any charities that could help pay for the air ambulance?</a>
                        <p>unfortunately, charities are limited in what they can do for patients who truly need air ambulance. if your patient is in need of highly reduced rates, let us try to help facilitate that through some of our less expensive options.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="46" id="46">46. is there an extra charge for oxygen?</a>
                        <p>there's no extra charge for oxygen; in fact, air ambulances are medically equipped with all advanced life support equipment. additionally, medications are supplied at no extra charge, the medical staff is on board at no extra charge, and we also include ground transportation on both sides of the trip.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="47" id="47">47. does it cost more to go on the weekend?</a>
                        <p>there is no additional cost; we arrange and coordinate our flight schedule around when you want to fly. because we are an air ambulance, we fly 24 hours a day 7 days a week. sometimes we can reduce the cost of the trip by connecting your flight with other customers' flights and pass the savings on to you.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="48" id="48">48. how much will it change the price if my dad goes on the trip?</a>
                        <p>it doesn't change the price at all. there is no charge for the additional passenger, and in some cases, we can even take two passengers.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="49" id="49">49. are there any discounts for aarp?</a>
                        <p>while we do not offer additional discounts, when our patient transport specialist quote a price it will be the lowest possible cost for the specific needs of the patient. we carefully explain all available options and work with you to find the most reasonable alternative for your needs.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="50" id="50">50. can i give you more than one credit card to pay for the trip?</a>
                        <p>yes. you can give us as many credit cards as you like to guarantee the trip. often, families come together to share in the cost. after you tell us how much to charge each card, we will authorize the cards prior to the trip and will charge them after the trip has been successfully completed.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="51" id="51">51. can i pay you after the flight?</a>
                        <p>you can definitely pay us after the flight, either with a wire transfer or a check. however, the trip must be guaranteed with a credit card prior to scheduling the trip. if you choose to send funds within 48 hours of the completion of the trip, your credit card will not be charged at all and the fund authorization is released from the card.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="52" id="52">52. is financing available?</a>
                        <p>we accept financing by the use of one or more credit cards. we require a guarantee of payment prior to the trip.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="53" id="53">53. is authorizing a card the same as charging it?</a>
                        <p>authorizing ensures that the total amount of the funds for the flight is available and ensures that we can charge the card after completing the trip.
                        </p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="54" id="54">54. why is your company more or less expensive than the other companies i've talked to?</a>
                        <p>when it comes to medical air travel, you need to consider the history of the company, the types of nursing and medical staff that will fly the trip, whether all services are included in the quote, the equipment and the planes that are used. we will work with you to insure the highest quality of service and care at the lowest possible price. it's important to get written quotes that carefully spell out all of the services, the type of plane, the medical equipment and medications on board, the qualifications of the pilots and medical crew, whether ground transportation is included, and insurance and licenses. all things being equal, u.s. air ambulance will meet or beat a written estimate from an accredited and licensed company.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="55" id="55">55. is air ambulance more expensive than a charter company?</a>
                        <p>many people don't realize this, but air ambulance can, in reality, be cheaper than charter. the reason is because charter flights are taxable, and the tax on the flight is often more than the cost of our nurses for a medical flight.</p>
                        <p>keep in mind, also, that charter will not offer any of the services of u.s. air ambulance: medical personnel, medical equipment, facility coordination, ground transportation and constant communication during the course of the trip.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="56" id="56">56. is the price you will quote for me round trip or one-way?</a>
                        <p>the price we typically quote is one-way. however, we can customize your trip to fit your needs. in some cases, the cost of a round trip may not be significantly more than the cost of a one way trip; for instance, if we're taking the patient to a doctor's appointment, our plane and crew can stand by and wait for that to be finished and they can bring the patient back to where they started from for significantly less than twice the cost of one way.</p>
                      </ListGroupItem>

                    </ListGroup>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4">
                <Row>
                  <Col sm="12">
                    <ListGroup flush>
                      <ListGroupItem>
                        <a name="57" id="57">57. does insurance, medicaid or medicare pay for this service?</a>
                        <p>about one-third of our clients have some insurance coverage for air ambulance transport. in most cases, medical necessity must be shown. medicaid, in some instances of medical necessity, will cover air ambulance service. medicare and medicare gap insurances generally do not cover air ambulance. our insurance specialist will work with you to obtain prior authorization from your insurance carrier, to submit claims for reimbursement and to follow through on other insurance issues.</p>
                      </ListGroupItem>
                      <ListGroupItem>
                        <a name="58" id="58">58. what forms of insurance do you accept?</a>
                        <p>we work with all of the major comprehensive health care insurers, for example blue cross &amp; blue shield, aetna, aig and many others. we will work with your insurance company to determine your coverage and we will accept their letter of guarantee. you will be responsible only for the amount that they do not cover.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="59" id="59">59. will you help me submit insurance papers?</a>
                        <p>once the trip is completed, our insurance claims personnel will assist you to get quick reimbursement from your insurance company. we will submit the paperwork and cut through the red tape. it will help if we have all the necessary information prior to the trip so that the claims process can be started quickly.</p>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tabId="5">
                <Row>
                  <Col sm="12">
                    <ListGroup flush>

                      <ListGroupItem>
                        <a name="60" id="60">60. what do you mean by "bedside-to-bedside" service?</a>
                        <p>for continuity of care, it is vital that our medical team starts and finishes the entire journey with the patient. that's why our medical team arrives at your patient's bedside at the start of the transfer. we can assess the patient's condition, comfort her so she is less anxious, and travel with her by ambulance to the airplane. at the end of the trip, our team is there to meet her and escort her by ambulance to the new facility, staying with her until she is safely and comfortably accommodated in her room at the receiving facility. we discuss the trip process with the receiving nurse so she is aware of any issues the patient may have had. in this way, your loved one is assured the utmost in care.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="61" id="61">61. what type of medical personnel do you provide?</a>
                        <p>at a minimum, all air ambulance trips require a critical care paramedic. u.s. air ambulance's standard of care is one critical care paramedic and one critical care registered nurse. when necessary, we provide respiratory therapists, nurse practitioners and physicians. our medical personnel have a minimum of five years of care experience and extensive training in flight physiology and air ambulance protocol.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="62" id="62">62. what type of medical equipment and medications are on board?</a>
                        <p>a partial list of supplies and equipment includes oxygen, ventilator, cardiac monitor/defibrillator, suction equipment, full acls drug complement, iv supplies, resuscitation equipment, faa approved stretchers, ac inverters and much more. see medical section of this website for a more comprehensive list of equipment and medications, as well as our medical transport protocol.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="63" id="63">63. who is your medical director?</a>
                        <p>dr. roque rodriguez, u.s. air ambulance's medical director, enforces strict protocols that exceed national standards. he will personally review your patient's case and will provide specific protocols for your patient.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="64" id="64">64. how many medical people go on each trip?</a>
                        <p>we typically outfit the aircraft with two aero-medical specialists on each trip, but we will send critical care nurses or paramedics, respiratory therapists and doctors as required by the patient's medical condition.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="65" id="65">65. what kind of training do they have?</a>
                        <p>our nurses and paramedics must have at least five years of experience either in er or first response to trauma before we hire them. then they also go through training to become air certified prior to flying. in addition, they fly with a preceptor (an advanced-level teacher) prior to managing patient care. we have ongoing training in protocols and patient care as well as quality review of each trip that is performed.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="66" id="66">66. is there a doctor on board?</a>
                        <p>for your peace of mind, and when needed due to the patient's condition, we can definitely supply one of our aero-medical doctors onboard. however, our doctors are not normally required because our rns and paramedics are highly qualified to deal with all types of patient care, including emergency situations. our medical director, dr. roque rodriguez, is available 24 hours per day to receive calls from the flight crew and to assist their decision making if necessary.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="67" id="67">67. will the nurses be able to administer medications during the flight?</a>
                        <p>yes. our aircraft is supplied with an array of advanced life support medications that can be used to safely transport the patient. one reason we get the medical report is so our nurses are aware of what type of medications the patient is taking, to be prepared to administer them during the flight.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="68" id="68">68. what happens if the patient has an emergency during the trip?</a>
                        <p>fortunately, an occurrence like this is rare, because before a patient ever goes into an emergency condition our medical team is monitoring and adjusting her condition to manage a safe, steady transport. however, if an upset condition were to occur and we could not stabilize the patient, we would call the nearest tower and request an emergency landing. we would then call the office to coordinate an emergency ground ambulance. we would have rescue units on the ground, so when we landed, we could take the patient directly to the nearest hospital, and we would contact the family member or case worker immediately.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="69" id="69">69. my patient has special medication needs. how can i get those taken care of during the flight?</a>
                        <p>after obtaining a medical report, we know the medications that the patient is taking. if we carry those medications, we will gladly manage them for the patient. if we don't have them on board, we will obtain the medications from the attending doctor of the sending facility. we can administer medications en route.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="70" id="70">70. why do you need two nurses on board?</a>
                        <p>it's part of our protocol to have two medical personnel on each flight for most cases. if, after the medical report is completed, our chief flight nurse concludes that only one is required, she will confer with the medical director. at that point we may be able to schedule just one nurse and pass the savings on to our client.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="71" id="71">71. my mother is a nurse. can she take the place of one of the nurses?</a>
                        <p>your mother is more than welcome to come on the trip. many patients respond well to having family members with them. unfortunately, she would not be able to assist our medical personnel. they operate by protocols under the direction of our medical director and have specialized training in aero-medical care using our flight medical equipment, and are insured for medical omissions. we have full responsibility for the patient once we are aboard the aircraft and must be able to quickly manage the patient's care in the event of an emergency. it is really in the patient's best interest that only our select medical team attend to the patient in transport.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="72" id="72">72. my mother can't sit up. will she be lying down on the plane?</a>
                        <p>if lying down is her most comfortable position that is easily accommodated. we have a medically equipped stretcher that works like a narrow hospital bed. it can be positioned flat or at an incline for the patient's comfort. if she chooses, she can lie down in total comfort for the entire flight. if she would like to sit for a while, we can raise the back of the stretcher and put a pillow behind her to make her as comfortable as possible.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="73" id="73">73. what is involved in getting the patient released from the hospital?</a>
                        <p>there are basically four types of releases from a hospital: general discharge; discharge to a facility of equal or greater care; discharge to a step-down facility; and discharge against medical advice (ama).</p>
                        <p>with a general discharge, all that is needed is coordination with the sending facility and the family. with a discharge to an equal facility or to a step-down facility, additional steps are required. the doctor at the sending facility must speak to a doctor at the receiving facility who accepts responsibility for the patient and calls admissions for a bed assignment. admissions will take responsibility for accepting the patient. sometimes there is difficulty in trying to get the doctors to communicate in a timely fashion. but we can assist in that, to coordinate and facilitate their speaking to each other. if the sending facility will not discharge the patient, we can still transport them ama. however, it is imperative in that situation that a receiving doctor and facility have agreed to accept the patient and assigned them a bed prior to the transport.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="74" id="74">74. my mom is in a nursing home. does she really need all that equipment?</a>
                        <p>she may not need it but it's there in case she does, and that's a comforting thought.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="75" id="75">75. my doctor won't release the patient. can you take her ama?</a>
                        <p>yes. we can transport a patient who has been discharged against medical advice (ama). if our medical director deems the patient safe for transport, he will give us the authority to transport her. because she is being discharged ama, she will need to be transported to a facility that will give her at least the same level of care. if you need help in scheduling the receiving facility, we can help you with that.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="76" id="76">76. my doctor filled out a dnr. will you honor it?</a>
                        <p>absolutely. we will honor any do not resuscitate (dnr) that is completed by your physician and that contains the required information for transport. we will send a copy of your dnr to our medical director for review to ensure that it meets the requirements for transport. if necessary, our medical director can fill out a dnr for you that meets all the requirements. we will diligently manage this for you prior to the trip so that your wishes can be honored.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="77" id="77">77. what if my father becomes unstable or passes away before the trip? will you charge me?</a>
                        <p>we understand that this can happen. it doesn't happen often, but when it does, we don't want you to incur any charges, if possible. if your father becomes unstable and for some reason needs to delay the flight, as long as it's more than 24 hours prior to the trip, there are no charges whatsoever. at that time we will be updating our medical report to reflect your father's condition. if, during that 24-hour window, he became unstable and could not continue, there would be a cancellation charge that could be applied to a later flight when he is able to fly. if it happens shortly before the flight and we have already repositioned an aircraft, you will be charged only the actual costs incurred up to that point.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="78" id="78">78. my mother is very ill. what happens if she passes away during the trip?</a>
                        <p>it is rare that a patient passes away on an air ambulance trip. if your mother becomes unstable and seems to be taking a turn for the worse, we will do everything in our power to stabilize her. if she passes away during the trip, we will make every effort to complete the trip and will call the authorities en route to make the necessary arrangements for pronouncing the patient upon arrival.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="79" id="79">79. my father has end-stage emphysema and is on high oxygen. can you take him under this condition?</a>
                        <p>yes, a patient with high oxygen needs is a perfect candidate for air ambulance. when a patient needs oxygen on the ground, they will need more in the aircraft. when a plane rises in elevation a negative pressure condition results, the air inside the plane expands and a patient's lungs take in less oxygen with each breath. however, air ambulances carry 100 percent supplement oxygen that can be given by nasal canula or mask. before take-off, the nurse will place a pulseoximeter on the patient's finger and measure his oxygen saturation. as the plane rises in elevation, we will monitor his o2 level and dial in more supplemental oxygen as needed. and, unlike commercial airlines, if we have to fly at lower altitudes to maintain his oxygen, we can and will to maintain his safety and care.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="80" id="80">80. my mother is on oxygen and i am concerned about taking her on a commercial airline. is there a problem doing so?</a>
                        <p>we can take patients who require oxygen on commercial flights with a nurse escort, but there are certain limitations. most airlines can provide up to four liters per minute provided our doctor signs a prescription and request and the patient is traveling with a medical escort. since a patient needs more oxygen in the air than on the ground, a patient should be using no more than 2.5 liters per minute on the ground to qualify. our nurse will monitor the patient's oxygen level on the ground and in the air to ensure that she is receiving adequate o2. another limitation is that many flights are not nonstop and the patient will require o2 at the connection airport as well as on the trips to and from the airports. our flight team can arrange this for the patient as well as monitor the trip and the oxygen providers to ensure that oxygen is available from start to finish.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="81" id="81">81. can you fly a pregnant woman?</a>
                        <p>yes. pregnancy cases are very specialized requiring special neonate teams and equipment. our medical team will work closely with the family to ensure a safe transport.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="82" id="82">82. my baby is 35 weeks old and needs to be transported to another hospital. are there special precautions that i should be concerned about?</a>
                        <p>premature infants have special needs during ambulance flights that require the use of neonate teams and equipment including isolettes. our special team has extensive experience with preemies and they will know exactly how to care for your child.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="83" id="83">83. my mother is on a vent. are you equipped to handle that?</a>
                        <p>yes. for vent patients we add a critical care nurse and respiratory therapist specially trained to manage the ventilator to the medical crew. we have a ventilator and all ancillary equipment on board the aircraft, along with other advanced life support equipment. the medical team will go into the hospital to assess your vented patient prior to the trip. we then switch her to the equipment and stabilize her prior to transport out to the plane. when we arrive at the destination, we reverse the process and go with her into the hospital to switch the equipment and stabilize her. in addition, prior to departing, we thoroughly review the trip report with the attending nurses so they know what vent settings were used during the trip and how the patient responded.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="84" id="84">84. my brother weighs 543 pounds. can you move him?</a>
                        <p>we can transport your brother. we will use a special aircraft because of his weight, and because he's only 5/3" we have to use an extra-wide stretcher. to get him into the aircraft, we will arrange a lift-type system, and will request additional personnel at both the sending and receiving airports to manage the transfer.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="85" id="85">85. my mother requires blood transfusions. can you do that?</a>
                        <p>usually with blood transfusions we like to provide them before we take off, so she doesn't have to have the transfusion on the plane where we have fluids that can provide blood volume increases. however, if the only way to transport her is to provide transfusion en route, we can manage that as well with advanced notice.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="86" id="86">86. my father is on a heart pump. are your personnel trained for that?</a>
                        <p>patients on a balloon pump require specialized care and equipment. we have the capability to manage that specialized patient. much care and preparation goes into this type of transport and it does required advanced notice.</p>
                      </ListGroupItem>

                      <ListGroupItem>
                        <a name="87" id="87">87. my brother has a morphine drip. can you administer morphine?</a>
                        <p>morphine is one of the many advanced life support medications that we carry on board our ambulance aircraft, and we can administer it for him. because of the medical information we've acquired prior to the trip, we will know about the morphine and any other medications he requires, and will have all necessary medications on board.</p>
                      </ListGroupItem>

                    </ListGroup>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>

          </Row>
        </Container>
      </section>
      <CtaComponent></CtaComponent>
    </div>)
  }
}

export default FAQ

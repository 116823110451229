import React, {Component} from 'react'
import {Container, Row, Col} from 'reactstrap';
import CtaComponent from '../../../containers/callToAction'
import projectsHeaderImage from '../../images/flag.jpg'

class OurStaff extends Component {
  render() {
    return (<div>
      <div className="img-header">
        <h1>Past Projects</h1>
        <h2>here are just a few of the major projects we've been involved with.</h2>
      </div>
      <section className="about-us">
        <Container>
          <Row>
            <Col style={{}} md={12}>
              <p>
                In addition to providing exemplary medical transportation and care to individuals and their families, U.S. Air Ambulance is also frequently asked by key government agencies to assist with special projects that involve evacuations from national and international emergencies. These agencies turn to U.S. Air Ambulance because of our global capability, greatest availability, highly-credentialed and trained medical staff, and 100% safety record.
              </p>
              <br></br>
              <br></br>
              <Row className="wow fadeInUp service-grid" data-wow-delay="0.6s">
                <Col className="service" md={6} sm={12}>
                <h3> Department of Defense </h3>
                <p>We provided 24-hour medical staff and air ambulance emergency evacuation capability for a four-month Star Wars project on the remote Alaskan island of Shemya for the U.S. Department of Defense.</p>
                </Col>
                <Col className="service" md={6} sm={12}>
                  <h3> Armed Forces Evacuation </h3>
                  <p>
                    We participated in a three-company team that included Skylink and HRG Enterprises to provide comprehensive evacuation capability to contractors in Iraq. After nine months of development, the project was not enacted due to rising sectarian violence.
                  </p>
                </Col>
                <Col className="service" md={6} sm={12}>
                  <h3> Mass Casualty Evacuation </h3>
                  <div>
                    <p>
                      We provided consultation and a comprehensive medical evacuation plan to manage a full scale, mass evacuation of patients from a cruise line in the event of terrorist attack or other mass casualty event. These efforts were coordinated with the U.S. Coast Guard.
                    </p>
                  </div>
                </Col>
                <Col className="service" md={6} sm={12}>
                  <h3> Homeland Security </h3>
                  <div>
                    <p>
                      Since 2005, we have transported more than 800 patients per day from the U.S. to their homelands in Mexico and Central and South America on behalf of the Department of Homeland Security.
                    </p>
                  </div>
                </Col>
                <Col style={{marginLeft: 0 }} className="service" md={6} sm={12}>
                  <h3> Hurricane Evacuation </h3>
                  <div>
                    <p>
                      We moved 1,500 Florida patients out of harm's way in the 24-hour period prior to landfall of Hurricane Wilma for the U.S. Marshals Service.
                    </p>
                  </div>
                </Col>
              <br></br>
              <br></br>
                <p>
                  We're proud to be a valued partner with these and other U.S. Government agencies, just as we value the trust of more than 97,000 patients we have transported across the country and around the world since 1990.
                </p>
                <br></br>
                <br></br>
                <p>
                  Our Patient Transport Specialists are personally available 24 hours a day, 52 weeks a year to answer your phone call; or send us an email and we will respond promptly.
                </p>
              </Row>
            </Col>
          </Row>
            </Container>
          </section>
          <CtaComponent></CtaComponent>
        </div>)
  }
}

export default OurStaff

import React, { Component } from 'react';
import { connect } from 'react-redux'
import HomeComponent from '../../components/home'

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeComponent);

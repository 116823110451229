import React, {Component} from 'react'
import {Container, Row, Col} from 'reactstrap';
import CtaComponent from '../../../containers/callToAction'
import pricingHeaderImage from '../../images/pricing.jpg'
import { Link } from 'react-router-dom'

class UnderstandingInsurance extends Component {
  render() {
    return (<div className="pricing">
      <div className="img-header">
        <h1>Understanding Insurance</h1>
      </div>
      <section>
        <Container>
          <Row>
            <Col md={12}>
              <h3> Medicare Coverage of Ambulance Services </h3>
              <p> Because many of our clients rely on Medicare and have questions about what Medicare will and will not pay for, we have included this information from the Centers for Medicare & Medicate Services. For more information, please contact Medicare directly at 1-800-633-4227. </p>

  				<p>Medicare Part B covers ambulance services to or from a hospital or skilled nursing facility ONLY when other transportation would be dangerous to your health. In addition, you can be transported from your home or sometimes from a medical facility to get care for a health condition that requires you to be transported only by ambulance.</p>

  				<h3>Emergency Ambulance Transportation</h3>

  				<p>Emergency ambulance transportation is provided after you have had a sudden medical emergency, when your health is in serious danger, and when every second counts to prevent your health from getting worse.</p>

  				<p><strong>Some examples of when emergency ambulance transportation may be covered include when you:</strong></p>
  				<ul>
  				<li>Are in severe pain, bleeding, in shock, or unconscious</li>
  				<li>Need to be restrained to keep you from hurting yourself or others</li>
  				<li>Need oxygen or other skilled medical treatment during transportation</li>
  				</ul>

  				<p>These are only examples of when Medicare would cover your ambulance trip. Coverage would depend on the seriousness of your medical condition and whether you could have been safely transported by other means. </p>

  				<p>Medicare will only cover ambulance services to the nearest appropriate medical facility that is able to give you the care you need. If you choose to be transported to a facility farther away, Medicare's payment will be based on the charge to the closest facility. If no local facilities are able to give you the care you need, Medicare will help pay for transportation to a facility outside of your local area.</p>

  				<h3>Air Ambulance Medical Transportation</h3>

  				<p>Medicare will pay for emergency ambulance transportation in an airplane or helicopter if your health condition requires immediate and rapid ambulance transportation that ground transportation can't provide. </p>

  				<h3>Non-Emergency Ambulance Transportation</h3>

  				<p>Non-emergency ambulance transportation is provided when you need transportation to diagnose or treat your health condition and you can't be transported another way. You must have orders from your doctor or other health care provider for Medicare to cover non-emergency ambulance transportation.</p>

  				<p>In some cases, Medicare covers limited non-emergency ambulance transportation if you are confined to your bed and you have a statement from your doctor saying that ambulance transportation is necessary because of your medical condition. Even if you aren't confined to your bed, in some cases, Medicare may still cover your non-emergency ambulance trip if you have a statement from your doctor.</p>

  				<p>If the ambulance company believes that Medicare won't pay for your non-emergency ambulance service, they might ask you to sign an Advance Beneficiary Notice (ABN). You will be asked to choose an option by marking a box and signing the ABN. If you sign the ABN, you are responsible for paying the cost of the trip if Medicare doesn't pay.</p>

  				<p>If you refuse to sign the ABN, the ambulance company can decide whether or not to take you by ambulance. If the ambulance company decides to take you after your refusal to sign, you may be responsible for paying the cost of the trip if Medicare doesn't.</p>

  				<p>However, if Medicare doesn't pay for the ambulance trip and you believe it should have been covered, you may submit an appeal. You must receive the service in order to appeal Medicare's payment decision.</p>

  				<p>You won't be asked to sign an ABN in an emergency situation.</p>

  				<h3>What does Medicare pay?</h3>

  				<p>If Medicare covers your ambulance trip, Medicare will pay 80% of the Medicare-approved amount after you have met the yearly Part B deductible ($131 in 2007). Medicare's payment may be different if you get services from a hospital-based ambulance company.</p>

  				<h3>What do I pay?</h3>

  				<p>If Medicare covers your ambulance trip, you pay 20% of the Medicare-approved amount, after you have met the yearly Part B deductible ($131 in 2007). In most cases, the ambulance company can't charge you more than 20% of the Medicare-approved amount. What you pay may be different however, if you get services from a hospital-based company. All ambulance companies must accept the Medicare-approved amount as payment in full.</p>

  				<h3>How do I know if Medicare didn't pay for my ambulance service?</h3>

  				<p>You will get a Medicare Summary Notice (MSN), from the Medicare Administrative Contractor (MAC)* (the company that handles bills for Medicare). The notice will tell you why Medicare didn't pay for your ambulance trip. </p>

  				<p>For instance, if you chose to go to a facility further than the closest one, you would get this statement on your notice: "Payment for ambulance transportation is allowed only to the closest appropriate facility that can provide the care you need." Or, if you used an ambulance to move from one facility to one closer to home, your notice would say:</p>

  				<p>"Transportation to a facility to be closer to your home or family isn't covered." These are only examples of statements you may see on your notice. Statements vary depending on your situation.</p>

  				<p>Call 1-800-MEDICARE (1-800-633-4227), if you have questions about what Medicare paid. TTY users should call 1-877-486-2048.
  				What can I do if Medicare doesn't pay for an ambulance trip I think should be covered?</p>

  				<p>You or someone you trust should carefully review your MSN and any other paperwork about your ambulance bill. You may find paperwork problems that can be fixed. You can also call your Medicare Administrative Contractor (MAC), the company that pays Medicare Part B bills, to get a more detailed explanation of why Medicare denied payment.</p>

  				<p>While reviewing your MSN and other paperwork, you may find that Medicare denied your claim because:</p>
  				<ol>
  				<li>The ambulance company didn't fully document why you needed ambulance transportation. If this happens, contact the doctor who treated you or the discharge social worker at the hospital to get more information about your need for transportation. You can send this information to the MAC.</li>
  				<li>The ambulance company didn't file the proper paperwork. If this happens, you can ask the ambulance company to refile your claim form. Don't pay the bill until the ambulance company has done this. If the ambulance company won't refile your claim,
  				contact your MAC. Your MAC will contact the ambulance company on your behalf to make them aware of their responsibility for filing a Medicare claim. If refiling your claim doesn't result in payment, you may file an appeal.</li>
  				</ol>

  				<h3>What if Medicare still won't pay?</h3>

  				<p>If you have Medicare, you have certain guaranteed rights to help protect you. One of these rights is the right to a fair, efficient, and timely process for appealing decisions about health care payment or services.</p>

  				<p>If Medicare doesn't cover your ambulance trip, you have a right to appeal. An appeal is a special kind of complaint you make if you disagree with decisions made by Medicare. To file an appeal, carefully review your MSN. It will tell you why your bill wasn't paid, how long you have to file an appeal, and what appeal steps you can take. If you decide to file an appeal, ask your doctor or provider for any information that might help your case. You should keep a copy of everything you send to Medicare as part of your appeal. If you need help filing an appeal, call 1-800-MEDICARE (1-800-633-4227) to get the number for the State Health Insurance Assistance Program in your state.</p>

  				<p>For more detailed information about appeals and other Medicare rights and protections, visit <a href="http://www.medicare.gov" title="">www.medicare.gov</a> to read or print the booklet "Your Medicare Rights and Protections." You can also call 1-800-MEDICARE (1-800-633-4227) to find out if this booklet is available in print.
  				For information about Medicare and related topics call 1-800-MEDICARE (1-800-633-4227) for answers and information 24 hours a day, every day. OR visit <a href="http://www.medicare.gov" title="">www.medicare.gov</a> on the web.</p>


            </Col>
          </Row>
        </Container>
      </section>
      <CtaComponent></CtaComponent>
    </div>)
  }
}

export default UnderstandingInsurance

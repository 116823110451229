import React, {Component} from 'react'
import {Col, Row, Container} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icons from "@fortawesome/fontawesome-free-solid"
import {AutoAffix} from 'react-overlays'
import { Link } from 'react-scroll'


class ServiceDetails extends Component {

  render() {
    return (<Container>
        <Row>
          <Col className="service-menu" md={3}>
            <AutoAffix viewportOffsetTop={130} container={this}>
              <div>
              <div className="card">
                <div className="list-group list-group-flush">
                  <Link activeClass="active" offset={-130} className="list-group-item list-group-item-action d-flex justify-content-between" to="what" spy={true} smooth={true} duration={500} >
                    <div>
                      <span>What is {this.props.service.title}?</span>
                    </div>
                  </Link>
                  {this.props.service.whenQuestions.length > 0 && <Link activeClass="active" offset={-130}  className="list-group-item list-group-item-action d-flex justify-content-between" to="when" spy={true} smooth={true} duration={500} >
                    <div>
                      <span>When is it the best option?</span>
                    </div>
                  </Link>}
                  {this.props.service.howQuestions.length > 0 && <Link activeClass="active" offset={-130} className="list-group-item list-group-item-action d-flex justify-content-between" to="how" spy={true} smooth={true} duration={500} >
                    <div>
                      <span>How much does it cost?</span>
                    </div>
                  </Link>}
                    {this.props.service.whyQuestions.length > 0 && <Link activeClass="active" offset={-130} className="list-group-item list-group-item-action d-flex justify-content-between" to="why" spy={true} smooth={true} duration={500} >
                    <div>
                      <span>Why choose US Air Ambulance?</span>
                    </div>
                  </Link>}
                </div>
              </div>
              <div style={{ }} className="phone-number">
                <a className="number-button" href="tel:1-800-948-1220">1-800-948-1220</a>
              </div>
              </div>
            </AutoAffix>
          </Col>
          
          <Col md={{size:8, offset: 1}} sm={12} className="service-detail-text">

            <div>
              <h4 name="what">What is {
                  this.props.service.title === "Air Ambulance"
                    ? "an "
                    : "a "
                }
                {this.props.service.title}?</h4>
              <div>
                {this.props.service.description}
              </div>
              {
                this.props.service.whatQuestions.map(function(object, i) {
                  return (<div key={object.question}>
                    <h5>{object.question}</h5>
                    <p>{object.answer}</p>
                  </div>)
                })
              }
              <div className="text-right"></div>
            </div>

            <div>
              {this.props.service.whenQuestions.length > 0 && <h4 name="when">When is it the best option?</h4>}
              {this.props.service.whenQuestions.length > 0 &&
                this.props.service.whenQuestions.map(function(object, i) {
                  return (<div key={object.question + i}>
                    {object.question && <h5>{object.question}</h5>}
                    <p>{object.answer}</p>
                  </div>)
                })
              }
              { this.props.service.whenQuestions.length > 0 &&
              <div className="text-right">
                <a href="#section_1" data-scroll-to="" data-scroll-to-offset="50" className="text-dark text-sm font-weight-bold">Back to top<FontAwesomeIcon icon={Icons.faLongArrowAltUp} />                </a>
              </div>
            }
            </div>
            <div>
              {this.props.service.howQuestions.length > 0 && <h4 name="how">How much does it cost?</h4>}
              { this.props.service.howQuestions.length > 0 &&
                this.props.service.howQuestions.map(function(object, i) {
                  return (<div key={object.question + i}>
                    {object.question && <h5>{object.question}</h5>}
                    <p>{object.answer}</p>
                  </div>)
                })
              }

              { this.props.service.howQuestions.length > 0 &&
              <div className="text-right">
                <a href="#section_1" data-scroll-to="" data-scroll-to-offset="50" className="text-dark text-sm font-weight-bold">Back to top<FontAwesomeIcon icon={Icons.faLongArrowAltUp} />                </a>
              </div>
              }

            </div>
            <div>
              {this.props.service.whyQuestions.length > 0 &&  <h4 name="why">Why choose US Air Ambulance?</h4>}
              {this.props.service.whyQuestions.length > 0 &&
                this.props.service.whyQuestions.map(function(object, i) {
                  return (<div key={object.question + i}>
                    {object.question && <h5>{object.question}</h5>}
                    <p>{object.answer}</p>
                  </div>)
                })
              }
              { this.props.service.whyQuestions.length > 0 &&
              <div className="text-right">
                <a href="#section_1" data-scroll-to="" data-scroll-to-offset="50" className="text-dark text-sm font-weight-bold">Back to top<FontAwesomeIcon icon={Icons.faLongArrowAltUp} />                </a>
              </div>
            }
            </div>
          </Col>
        </Row>
      </Container>
)}}

export default ServiceDetails

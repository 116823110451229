import React, {Component} from 'react'
import {Helmet} from "react-helmet";
import ServiceDetails from './serviceDetails'
import CtaComponent from '../../containers/callToAction'

import './services.css';

let services = {
  "air-ambulance": {
    title: "Air Ambulance",
    seoTitle: "Air Ambulance | Safe, Expedient Travel For Critically Ill Patients",
    seoDescription: "Air ambulance is the safest and fastest way to transport all patients from one hospital to another or to bring them home.",
    image: "/img/airambulance.jpg",
    description: "The appropriate choice of transport for patients requiring urgent care is air ambulance flight. With doctors, registered nurses, respiratory therapists, pediatric specialists and critical care paramedics on staff, U.S. Air Ambulance offers bedside to bedside service to critically ill patients in need of medical air transport.",
    whatQuestions: [
      {
        question: "Q: How is the aircraft staffed?",
        answer: "Our chief flight nurse will speak with the staff treating the patient to determine which aeromedical transfer specialists are required onboard the flight. All treatments, medications, and medical equipment will also be discussed so that they can be continued during the air ambulance transport."
      }, {
        question: "Q: Is ground transportation included?",
        answer: "We will access the needs of the patient and determine the appropriate ground transportation to take the patient from the facility to the airport where our plane will be landing. Upon arrival at the destination we will again have appropriate transportation waiting to take the patient to their final destination. In addition, if a passenger is accompanying the patient on the air ambulance, usually there is room on the ground transportation for them to ride with the patient."
      }, {
        question: "Q: Can passengers accompany the patient?",
        answer: "On an air ambulance we can usually accommodate one to two passengers in addition to the patient. The number of passengers will be determined once we have completed a medical evaluation of the patient. Please remember space inside the aircraft is limited so, luggage must be kept to a minimum."
      }
    ],
    whenQuestions: [
      {
        answer: "Air ambulance transport is the fastest method of transporting critically ill patients from one facility to another, or to bring them closer to loved ones. This is particularly important for international air ambulance transports, when it is necessary to fly a patient long distances with the assistance of qualified medical staff. Our aero-medically trained team, combined with the most sophisticated air ambulance equipment, ensures competent care for the most critically ill patients. There are only a few conditions that prohibit air ambulance transport: Patients in labor, Patients who have had heart surgery within 24 hours of the flight, Patients with unstable vital signs."
      }
    ],
    howQuestions: [
      {
        answer: "Based on the patient's condition - whether on Basic Life Support, Advanced Life Support, or with Acute Respiratory or Specialty Trauma issues - we assign the appropriate medical team. Each member of our air medical crew has a minimum of five years experience in critical care. They participate in rigorous annual training programs that ensure that their skills are at the highest level and they have leading edge information regarding aero-medical protocol and procedures. This includes courses in flight safety and patient management in the airborne environment."
      }
    ],
    whyQuestions: [
      {
        answer: "Us Air Ambulance is a leading air ambulance provider working to transport patients safely all over the world. We have a qualified compassionate staff available 24 hours a day 7 days a week to help you manage your patient transfer. We are a preferred provider to the Department of State, have worked to ensure NASA employees have excellent overseas support in case of a medical emergency, and also provided assistance to the Department of Homeland Security. "
      }
    ]
  },
  "medical-escort": {
    title: "Medical Escort",
    seoTitle: "Medical Escort | A Cheaper Alternative To Air Ambulance For Stable Patients",
    seoDescription: "Medical Airline Escort is a cost-effective option for stable patients that can sit during take-off and landing and that have low or no oxygen requirements.",
    image: "/img/commercial.jpg",
    description: "Medical Air Escort is a great alternative if the patient is in stable enough condition to sit during take-off and landing and has a limited need for oxygen. The patient flies comfortably in First Class or Business Class on a major commercial airline and is under constant care of at least one member of the U.S. Air Ambulance medical team. If the departing and arrival cities are major hubs for the airlines, direct flights of relatively short duration are often available.",
    whatQuestions: [],
    whenQuestions: [
      {
        answer: "Our Air Medical Service includes handling of all travel details Prior to the trip, our Patient Transport Specialists and Medical Team work with you to develop an itinerary that meets the needs of the patient. We'll research the flight options, and after reviewing the itinerary with you, we purchase the airline tickets. We'll also arrange all ground travel to and from the airport. Prior to the flight, our medical team does a complete physical assessment of the patient to ensure physical condition is stable enough for airline travel. During the trip, RNs and Paramedics are constantly in attendance, to ensure the comfort and safety of the patient during the commercial flight. They help with direct assistance during airport check-in, security clearances, aircraft boarding and de-planing. They also provide all medical care, including the administering of medications, and assistance with meals, hygiene, mobility, and other care issues. Once the trip is complete, we 'close the loop' by handling these post trip details. We'll conduct a post-trip medical assessment and provide a verbal report on the patient's current condition once we arrive at the receiving facility. Our medical team will stay with the patient until he/she is in the capable hands of personnel at the receiving facility. We follow up by completing and submitting all required paperwork."
      }
    ],
    howQuestions: [],
    whyQuestions: [
      {
        answer: "We Handle All Details of Your Medical Travel Details When you choose U.S. Air Ambulance, in addition to the medical care, all medical escort trip details are handled for you. Our services include: Itinerary details and ticketing. Ground travel to and from the airport. Direct assistance with check-in, security clearance, boarding and deplaning." }
    ]
  },
  "long-distance-ground": {
    title: "Long Distance Ground",
    seoTitle: "Long Distance Ground | Critical Care For Trips Under 1500 Miles",
    seoDescription: "Travel by ground ambulance or Amtrak is a cost-saving option for stable patients that must lie down during transport.",
    image: "/img/ambulance.jpg",
    description: "If the patient is not in critical condition and cannot or will not fly, Long Distance Ground is a cost-saving alternative to air ambulance travel. It is also an ideal way to move a patient from one facility to another, within a distance that would not be practical for air or rail travel.",
    whatQuestions: [],
    whenQuestions: [
      {
        answer: "Long distance ground is a great choice when the patient medical transport requires less than 24 hours of driving time and/or the trip is 1500 miles or under. Depending on the length of the trip and the total distance to be traveled, each patient will be administered to by one or two trained Registered Nurses or Critical Care Paramedics. Patient's Condition Only patients who are in need of Basic Life Support qualify for Long Distance Ground. Those patients requiring a ventilator or having a tracheostomy are not eligible. All patients are accompanied by a team of three medical personnel, two of whom share the driving responsibilities. Our ambulance crews strictly adhere to BLS protocols."
      }
    ],
    howQuestions: [],
    whyQuestions: [
      {
        answer: "We Handle All Details of Your Ground Ambulance Trip: When you choose U.S. Air Ambulance, in addition to the medical care, all trip details are handled for you. Our services include: Pre-trip medical intake physical assessment of the patient Communication between sending and receiving physicians and facilities All medical care, administer medications, assist with meals, hygiene, mobility, and other care issues Post-trip medical assessment and verbal report to receiving facility" }
    ]
  },
  "commercial-stretcher": {
    title: "Commercial Stretcher",
    seoTitle: "Commercial Stretcher | Expert Care For Non-Emergency Medical Transport",
    seoDescription: "Airline commercial stretcher is a transport option available to critical patients who are not on a ventilator.",
    image: "/img/commercialstretcher.jpg",
    description: "International Commercial Stretcher is a safe and effective method of international medical transport that is unique to U.S. Air Ambulance. If the patient qualifies for transport by commercial stretcher, this method can save the family tens of thousands of dollars. If a patient is in need of an international medical flight to or from Europe, the Middle East, Asia or the Pacific Rim, we can arrange a Commercial Stretcher trip that may well be a practical and cost-saving option for you.",
    whatQuestions: [],
    whenQuestions: [
      {
        answer: "U.S. Air Ambulance works with the selected commercial airline to remove several rows of seats from the aircraft to accommodate the patient's stretcher and all necessary medical equipment. This type of medical flight requires detailed coordination with the airlines and therefore it takes approximately 7 to 10 days to finalize all arrangements. This service is available on international flights going to and from the East and West. Unfortunately, commercial aircraft traveling between North and South America do not offer this type of service. International Commercial Stretcher offers a potential cost savings of 40% to 50% over private air ambulance flights. The longer the distance of the flight, the more cost savings are realized. Because of the travel time involved, all patients transported by International Commercial Stretcher are accompanied by at least two medical professionals, ensuring that the patient has constant medical attention for the duration of the flight. Both BLS and ALS patients are eligible for these international medevac flights - they can have a tracheostomy, but cannot be on a ventilator."
      }
    ],
    howQuestions: [],
    whyQuestions: [
      {
        answer: "We Handle All Details of Your International Commercial Stretcher Trip: When you choose U.S. Air Ambulance, in addition to the medical care, all trip details are handled for you. Our services include: Pre-trip medical intake physical assessment of the patient, Communication between sending and receiving physicians and facilities, All medical care: administer medications, assist with meals, hygiene, mobility, and other care issues, Ticketing, Arrangement of all ground travel to and from the airport, Direct assistance with airport check-in, security clearance and boarding on/off the aircraft, Customs and Immigration, Post-trip medical assessment and verbal report to receiving facility, Clearance through appropriate government agencies.",
      }
    ]
  },"train-escort": {
    title: "Train Escort",
    seoTitle: "Train Escort | Expert Care For Non-Emergency Medical Transport",
    seoDescription: "Medical escort via Amtrak is less expensive than air ambulance and long distance ground.",
    image: "/img/train.jpg",
    description: "Another viable option is Medical Escort via Amtrak. The patient is accompanied by a member of our medical team in a sleeper car of an Amtrak train. Medical escort via Amtrak is less expensive than air ambulance and long distance ground transportation. The very nature of train travel means that the patient will travel in a relatively stress-free environment with all of the amenities of rail travel provided. The medical escort assists with medications, meals, hygiene, mobility and all other care issues. All details and arrangements are handled by our Patient Transport Specialists.",
    whatQuestions: [],
    whenQuestions: [
      {
        answer: "Long distance ground is a great choice when the patient medical transport requires less than 24 hoursThere are many patients who qualify for long distance travel aboard an Amtrak train. Two important factors help determine if this safe, enjoyable mode of transport is particularly appropriate for your loved one: Time is not a prevailing concern The patient's condition requires traveling while lying down."
      },
    ],
    howQuestions: [{
      answer: "Medical Escort via Rail is a money-saving option when time is not a prevailing concern, and the patient's condition requires him/her to be lying down. However, this method requires pre-planning since we are dependent on Amtrak schedules and seat availability. Because of the scheduling and logistics involved, the trip requires 7 to 10 days of advance planning."
    }],
    whyQuestions: [
      {
        answer: "We Handle All Details of Your Medical Escort Service via Rail When you choose U.S. Air Ambulance, in addition to the medical care, all trip details are handled for you. Our 'turn key' services include: Itinerary details and ticketing. Ground travel to and from the rail station. Direct assistance with check-in, security clearance and boarding on/off the train."
      }
    ]
  }
}

class ServiceComponent extends Component {

  render() {

    return (
      <section className="service-description">
        {services[this.props.match.params.service] &&
      <div>
      <Helmet>
        <title>
          {services[this.props.match.params.service].seoTitle}</title>
        <meta name="description" content={services[this.props.match.params.service].seoDescription} data-react-helmet="true"/>
      </Helmet>
        <div className="img-header">
          <h1>{services[this.props.match.params.service].title}</h1>
          <h2>{services[this.props.match.params.service].seoDescription}</h2>
        </div>
      <ServiceDetails service={services[this.props.match.params.service]}></ServiceDetails>
      <CtaComponent></CtaComponent>
      </div>
      }
    </section>
    )
  }
}

export default ServiceComponent

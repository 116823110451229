import React, {Component} from 'react'
import {Container, Row, Col} from 'reactstrap';
import CtaComponent from '../../../containers/callToAction'

import "./about.css"
import aboutHeaderImage from '../../images/team.jpg'

class AboutComponent extends Component {
  render() {
    return (<div>
      <section className="about-us">
      <div className="img-header">
        <h1>About Us</h1>
        <h2>US Air Amubulance has decades of experience in transporting patients quickly while providing the expert level care they need</h2>
      </div>
        <Container>
          <Row>
            <Col md={12}>
              <p>
                Established in 1990, US Air Ambulance is a leading international medical transport company. We have transported more than 97,000 patients across America and around the globe - more than any other air ambulance company. We are proud of our 100 percent safety record.
              </p>

              <p>
                Here are just a few of the reasons why US Air Ambulance has earned the trust and respect of our valued clients and business partners:
              </p>

              <p>
                Patient Transport Specialists are dedicated to arranging fast, cost-effective travel, based on individual need and circumstances. Our phones are personally answered 24 hours a day, seven days a week. We develop an itinerary, handle logistics, communicate with the patient's family and medical staff professionals, monitor the progress of the entire transportation process from bedside to bedside and complete the necessary paperwork when the transport is completed. On average, our Patient Transport Specialist will make approximately 25 phone calls per transport. We offer a variety of travel options for medically-assisted travel to meet the needs of the patient and the budgetary considerations of the family. Planes are strategically located across the country to respond quickly and efficiently when a need arises. Our medical team is highly skilled, aero-medically trained and multi-lingual.
              </p>
              <p>
                Our safety record continues to be the highest in the international air ambulance industry - 100%. Our price guarantee assures that your family member or patient will receive competitive pricing on air and/or other medical transport choices to anywhere in the world. We promise to do whatever it takes to assure the best possible service and comfort for our patients and their families.
              </p>
              <h2>
                We Are Proud of Our 100% Safety Record
              </h2>
              <p>
                US Air Ambulance is a leading international medical transport company with a central command post is staffed 24 hours a day, 7 days a week and every phone call and email inquiry is answered personally. We fly both domestically and internationally and have extensive experience with both ground and air medical transport moving 97,000 patients with a 100% safety record.

              </p>
              <h2>
                Partnering with the US Government
              </h2>
              <p>
                We have been named a preferred provider to the US Department of State and its 278 posts around the world. We are often called upon to provide International Medical Flights for State Department employees and their family members; transporting them from outlying posts back to the United States or to other countries where appropriate medical care is awaiting them. We participate in a program for the Department of Homeland Security to move up to 800 foreign national patients each and every day from the US back to their home countries in places like Guatemala and El Salvador. We have provided International ambulance service to over 150,000 patients in this program thus far. US Air Ambulance is also a preferred provider to Medicare and Medicaid.

              </p>
              <h2>
                Hospital Evacuation Liaison Program
              </h2>
              <p>
                These experiences and others gave us the core skills needed to create our Hospital Evacuation Liaison Program (HELP): During Hurricane Katrina we worked under the direction of FEMA and were responsible for air medical evacuation of over 250 patients out of New Orleans. Prior to Hurricane Wilma, we moved 1,500 prisoners in two days to another facility out of the hurricane's path. During Hurricanes Gustav and Ike, we worked with two major hospital groups to transport critical patients ahead of the storms. Through our HELP program, we have contracted with over 500 hospitals from around the country to partially or fully evacuate their patients and staff during a large scale emergency. We have a network of over 600 transport companies coming from outside the impacted region to provide the contracted hospital with fixed-wing and rotor-wing air ambulances and critical care, ALS, and BLS ground ambulances, wheelchair vans, and buses. HELP includes a network of receiving facilities that have agreed to accept patients from the evacuated areas.
              </p>
            <h2>Our Mission</h2>
            <p>
            US Air Ambulance provides domestic and international medical transport of patients requiring various levels of assistance by coordinating services with the highest quality medical personnel, flight personnel and case workers in hospitals and insurance companies. We are committed to compassionate, professional transport of patients within our care, using state-of-the-art air and ground transportation which is fully equipped to meet all medical emergencies.
          </p>
            </Col>
          </Row>
        </Container>
      </section>

      <CtaComponent></CtaComponent>
    </div>)
  }
}

export default AboutComponent

import React from 'react'
import {Container} from 'reactstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './testimonials.css'

const Testimonials = props => (<section id="testimonials" className="wow fadeInUp">
  <Container>
    <div className="section-header">
      <h2>Testimonials</h2>
      <p>US Air Ambulance has received many letters from families expressing gratitude. Some of the comments we have received are paraphrased below.</p>
    </div>
      <OwlCarousel className="testimonials-carousel" className="owl-theme"
       loop
       dots={false}
       nav
       responsive={{
               0:{ items:1, autoHeight: true },
               600:{ items:3 },
           }}
      >
      <div className="testimonial-item">
        <img src="img/testimonial-1.jpg" className="testimonial-img" alt=""></img>
        <h3>Dr. & Mrs. Duane Schultz</h3>
        <h4>Francstown, NH</h4>
        <p> My wife and I wanted to thank you and your whole staff for the caring concern that you showed us. The professional manner that you all had was coupled with a warm concern that we both felt. </p>
      </div>

      <div className="testimonial-item">
        <img src="img/testimonial-5.jpg" className="testimonial-img" alt=""></img>
        <h3>John Janneillo</h3>
        <h4>Rochester, NY</h4>
        <p> Just wanted to say thank you for making a very difficult time for Alta Rufton and her brother, Robert Hinkle as easy as possible. You helped us fulfill Alta's last wish of returning to her true home. Thanks again for your kindness. </p>
      </div>

      <div className="testimonial-item">
        <img src="img/testimonial-3.jpg" className="testimonial-img" alt=""></img>
        <h3>Maribel Lee</h3>
        <h4>Scotch Plains, NJ</h4>
        <p> I would highly recommend your company to anyone who needed your service. You have the most caring crew I have ever met. They took great care of my grandfather and made my mother's flight back home safe and comfortable. </p>
      </div>

      <div className="testimonial-item">
        <img src="img/testimonial-2.jpg" className="testimonial-img" alt=""></img>
        <h3>Polly S. Freeze</h3>
        <h4>Clearwater, FL</h4>
        <p>My husband and I were impressed with your efficiency in arranging the ground and air transportation, and with your empathy and understanding of our concerns for my father's welfare. </p>
      </div>

      <div className="testimonial-item">
        <img src="img/testimonial-4.jpg" className="testimonial-img" alt=""></img>
        <h3>Juan Giovannetti M.</h3>
        <h4>Bogota, Columbia</h4>
        <p> Agradezco enormemente todo lo que hiciste por mi hermana. La atencion que le dieron fue excelente, sinceramente losfelicito por su servicio, y a ti te admiro por ese sentido humanitario quemostraste en todo momento </p>
      </div>

    </OwlCarousel>

  </Container>
</section>)

export default Testimonials

import React, { Component } from 'react'
import {
  Container, Col, Form, Row,
  FormGroup, Input,
  Button, FormFeedback,
} from 'reactstrap';
// import Moment from 'moment';
// import Autocomplete from 'react-google-autocomplete';
// import { ToastContainer } from 'react-toastify';
// import ReactGA from 'react-ga';

import 'react-toastify/dist/ReactToastify.css';
import './quote.css';


class QuoteForm extends Component {

  constructor(props) {
   super(props);
     this.state = {
     'email': '',
     'date': '',
     'number': '',
     'departing': '',
     'destination': '',
     validate: {
       emailState: '',
     },
   }
   this.handleChange = this.handleChange.bind(this);
 }

 validateEmail(e) {
   const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   const { validate } = this.state
     if (emailRex.test(e.target.value)) {
       validate.emailState = 'has-success'
     } else {
       validate.emailState = 'has-danger'
     }
     this.setState({ validate })
   }

 handleChange = async (event) => {
   const { target } = event;
   const value = target.type === 'checkbox' ? target.checked : target.value;
   const { name } = target;
   await this.setState({
     [ name ]: value,
   });
 }

 submitForm(e) {
   e.preventDefault();
   console.log(`${ JSON.stringify(this.state) }`)
 }

 render() {
    const { email, date, departing, destination, number } = this.state;
    return(
      <section id="get-a-quote" className='w-100 p-5 quote-form'>
        <h1> Get A Quick, Free Quote</h1>
        <h2> We're here to help you every step of the way</h2>
        <Container>
         <Form className="form" onSubmit={ (e) => this.submitForm(e) }>
          <Row>
           <Col className="quote-input" md={2}>
             <FormGroup>
               <Input
                 type="email"
                 name="email"
                 id="email"
                 placeholder="Your Email"
                 value={ email }
                 valid={ this.state.validate.emailState === 'has-success' }
                 invalid={ this.state.validate.emailState === 'has-danger' }
                 onChange={ (e) => {
                             this.validateEmail(e)
                             this.handleChange(e)
                           } }
               />
               <FormFeedback valid> </FormFeedback>
               <FormFeedback> </FormFeedback>
             </FormGroup>
           </Col>

           <Col className="quote-input" md={2}>
             <FormGroup>
               <Input
                 type="text"
                 name="departing"
                 id="departing"
                 placeholder="Departing From"
                 value={ departing }
                 onChange={ (e) => { this.handleChange(e) } }
               />
               <FormFeedback valid> </FormFeedback>
               <FormFeedback> </FormFeedback>
             </FormGroup>
           </Col>

           <Col className="quote-input" md={2}>
             <FormGroup>
               <Input
                 type="text"
                 name="destination"
                 id="destination"
                 placeholder="Destination"
                 value={ destination }
                 onChange={ (e) => { this.handleChange(e) } }
               />
               <FormFeedback valid>
               </FormFeedback>
               <FormFeedback>
               </FormFeedback>
             </FormGroup>
           </Col>

           <Col className="quote-input" md={2}>
             <FormGroup>
               <Input type="date" name="date" id="tripDate" placeholder="Date of Trip"
                 value={ date }
                 onChange={ (e) => { this.handleChange(e) } }
               />
             </FormGroup>
           </Col>

           <Col className="quote-input" md={2}>
             <FormGroup>
               <Input
                 type="tel"
                 name="number"
                 id="number"
                 placeholder="Your Number"
                 value={ number }
                 onChange={ (e) => { this.handleChange(e) } }
               />
               <FormFeedback valid>
               </FormFeedback>
               <FormFeedback>
               </FormFeedback>
             </FormGroup>
           </Col>


           <Col className="quote-input" md={2}>
             <FormGroup>
             <Button className="btn">Submit</Button>
             </FormGroup>
           </Col>
     </Row>
       </Form>



   </Container>
      </section>
    )
  }
}

export default QuoteForm

import React, {Component} from 'react'
import {Container, Row, Col} from 'reactstrap';
import CtaComponent from '../../containers/callToAction'
import { Link } from 'react-router-dom'

import pricingHeaderImage from '../images/pricing.jpg'
import "./pricing.css"

class Pricing extends Component {
  render() {
    return (<div className="pricing">
      <div className="img-header">
        <h1>Cost of Air Ambulance</h1>
      </div>
      <section>
        <Container>
          <Row>
            <Col md={12}>
              <p>As a premier air ambulance company, it's our goal to provide the highest quality medical transport services at the <Link to="/pricing/low-cost">lowest possible cost</Link>. Price is based a number of factors including:
                <br></br>
                <br></br>
                <ul>
                  <li>The medical condition of the patient</li>
                  <li>The type of aircraft utilized</li>
                  <li>The urgency of travel</li>
                  <li>The distance of the flight</li>
                  <li>Advance planning and booking (to ensure the lowest possible price)</li>
                </ul>
              </p>

              <p>
                Our Patient Transport Specialists will discuss these factors with you and will provide air ambulance rates over the phone and will then follow-up with a confirming email. They'll also be able to determine whether your patient is elligible for <Link to="/pricing/discount-options">discounted flights</Link>. The medical flight price quoted to you is the final cost - there are no hidden charges.
              </p>

              <p>
                We offer the following payment options for any of our medical transport services:
                <br></br>
                <br></br>

                <ul>
                  <li>All major credit cards</li>
                  <li>Wire transfers</li>
                  <li>Cashiers' checks</li>
                  <li>insurance company letters of guarantee</li>
                </ul>
              </p>
              <p>
                The cost of the transport can be covered by using multiple credit cards - just tell us the amount to be charged to each one. We authorize cards prior to the trip and charge them after the trip has been successfully completed.
              </p>

              <p>
                Additionally, we may be able to assist with the paperwork involved in requesting <Link to="/pricing/insurance">insurance</Link> guarantees and/or reimbursements.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <CtaComponent></CtaComponent>
    </div>)
  }
}

export default Pricing

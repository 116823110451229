import React from 'react'
import { Container, Row, Col} from 'reactstrap';
import { Link } from 'react-router-dom'

import './services.css'

const Services = props => (
  <section className="services">
  <Container>
  <Row>

  <Col md={5} sm={12}>

      <h3> US Air Ambulance is a Full Service Patient Transporter - We can accommodate all of your patient's needs</h3>
      <p> We are one of the very few medical transporters that both transport internationally and operate 24 hours a day, 365 days a year. We provide all options to meet your medical and financial needs, including domestic and international air ambulance, airline medical escorts, and international airline stretcher transports, as well as long distance ground and rail transports throughout the United States. </p>
  </Col>
  <Col md={{size:6, offset:1}} sm={12}>
    <Row className="wow fadeInUp service-grid" data-wow-delay="0.6s">
    <Col className="service" md={6} sm={12}>
      <i></i>
      <h3><Link to="/services/air-ambulance">Air Ambulance</Link></h3>
      <div>Air ambulance is the safest and fastest way to transport all patients from one hospital to another or to bring them home.</div>
    </Col>
    <Col className="service" md={6} sm={12}>
      <i></i>
      <h3><Link to="/services/medical-escort">Medical escort</Link></h3>
      <div>Medical Airline Escort is a cost-effective option for stable patients that can sit during take-off and landing and that have low or no oxygen requirements.</div>
    </Col>
    <Col className="service" md={6} sm={12}>
      <i></i>
      <h3><Link to="/services/long-distance-ground">Long Distance Ground</Link></h3>
      <div>Travel by ground ambulance or Amtrak is a cost-saving option for stable patients that must lie down during transport. </div>
    </Col>
    <Col className="service" md={6} sm={12}>
      <i></i>
      <h3><Link to="/services/train-escort">Train Escort</Link></h3>
      <div>Medical escort via Amtrak is less expensive than air ambulance and long distance ground. </div>
    </Col>
  </Row>
  </Col>

</Row>
</Container>
</section>
)


export default Services

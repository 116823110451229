import React, { Component } from 'react'
import { Row, Container, Col } from 'reactstrap'

import './cta.css';

class CtaComponent extends Component {

  render() {
    return(
        <section className="cta">
          <Container>
            <Row>
              <Col className="cta-data" md={12} sm={12}>
                <h3>Call For a Free Quote</h3>
                <h5>Have more questions? We're happy to answer them.</h5>
                <div className="phone-number">
                  <a className="number-button btn" href="tel:1-800-948-1220">1-800-948-1220</a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
    )
  }
}

export default CtaComponent

import React, {Component} from 'react';
import {Container, Row, Col} from 'reactstrap';
import "./footer.css"

class FooterComponent extends Component {
  render() {
    return (<footer id="footer" data-stellar-background-ratio="0.5">
      <Container>
        <Row>
              <Col sm={12} md={5}>
                <div className="footer_widget">
                  <h5 className="widget-title">
                    <span>US Air Ambulance</span>
                  </h5>
                  <div className="textwidget">
                    <p>U.S. Air Ambulance is a leading international medical transport company with a central command post is staffed 24 hours a day, 7 days a week and every phone call and email inquiry is answered personally.</p>
                  </div>
                </div>
                {false &&
                <div className="footer_widget">
                  <div className="socials-widget">
                    <a href="#" target="_blank">
                      <span className="fa fa-facebook"></span>
                    </a>
                    <a href="#" target="_blank">
                      <span className="fa fa-twitter"></span>
                    </a>
                    <a href="#" target="_blank">
                      <span className="fa fa-instagram"></span>
                    </a>
                  </div>
                </div>
              }

            </Col>
              <Col sm={12} md={{size:2, offset:1}}>
                <div className="footer_widget">
                  <h5 className="widget-title">
                    <span>More Info</span>
                  </h5>
                  <div className="menu-additional-links-container">
                    <ul id="menu-additional-links" className="menu">
                      <li id="menu-item-84" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-84">
                        <a href="/about/us-air-ambulance">About us</a>
                      </li>
                      <li id="menu-item-86" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-86">
                        <a href="/about/past-projects">Past projects</a>
                      </li>
                      <li id="menu-item-88" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-88">
                        <a href="/request-a-quote">Contact us</a>
                      </li>
                      <li id="menu-item-85" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-85">
                        <a href="/faqs">FAQ</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={2}>
                <div className="footer_widget">
                  <h5 className="widget-title">
                    <span>Services</span>
                  </h5>
                  <div className="menu-services-container">
                    <ul id="menu-services" className="menu">
                      <li>
                        <a href="/services/air-ambulance">Air Ambulance</a>
                      </li>
                      <li>
                        <a href="/services/medical-escort">Medical Escort</a>
                      </li>
                      <li>
                        <a href="/services/long-distance-ground">Long Distance Ground</a>
                      </li>
                      <li>
                        <a href="/services/commercial-stretcher">Commercial Stretcher</a>
                      </li>
                      <li>
                        <a href="/services/train-escort">Train Escort</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={2}>
                <div className="footer_widget">
                  <h5 className="widget-title">
                    <span>Pricing</span>
                  </h5>
                  <ul>
                    <li>
                      <a href="/pricing/">Pricing Information</a>
                    </li>
                    <li>
                      <a href="/pricing/insurance">Insurance Coverage</a>
                    </li>
                    <li>
                      <a href="/pricing/low-cost">Low-Cost Options</a>
                    </li>
                    <li>
                      <a href="/pricing/discount-options">Discount Flights</a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
      </Container>
    </footer>)
  }
}

export default FooterComponent

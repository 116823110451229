import React from 'react'
import { Container } from 'reactstrap';
import './hero.css'

const Hero = props => (<header>
  <div className="overlay"></div>
  <video playsInline autoPlay muted loop poster="/planesstart.png">
    <source id="mp4" src="/planes.mp4" type="video/mp4"></source>
  </video>
  <Container className="h-100">
    <div className="d-flex text-center h-100">
      <div className="my-auto w-100 text-white">
        <div className="header-content">
          <h1>We bring your loved ones home</h1>
          <h2>Join over 98,000 patients who have been transported since 1990 with a 100% safety record</h2>
          <div className="phone-number">
            <a className="number-button btn" href="tel:1-800-948-1220">1-800-948-1220</a>
            <a className="secondary-button btn" href="/request-a-quote">Get a Quote</a>
          </div>
        </div>
      </div>
    </div>
  </Container>

</header>)

export default Hero

import React, {Component} from 'react';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, NavbarNav, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'mdbreact';
import { NavLink } from 'reactstrap';
import { BrowserRouter as Router } from 'react-router-dom';

import "./navigation.css"

class NavigationComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isWideEnough: false
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }

  render() {
    let headerName = ''
    console.log(this.props.route);
    if(this.props.route !== "" && this.props.route !== "/"){
      headerName="service-header"
    }

    return (
      <div>
        <Router>
            <Navbar className={headerName} color="transparent" light expand="md" fixed="top" scrolling>
                  <NavbarBrand href="/">
                      <img src="/logo.png" height="50" alt="" />
                      <strong>US Air Ambulance</strong>
                  </NavbarBrand>
                { !this.state.isWideEnough && <NavbarToggler onClick = { this.onClick } />}
              <Collapse isOpen = { this.state.collapse } navbar>
                <NavbarNav right>
                  <NavItem active={window.location.pathname === '/'}>
                      <NavLink href="/">Home</NavLink>
                  </NavItem>
                  <NavItem active={window.location.pathname.startsWith("/about")}>
                    <Dropdown>
                       <DropdownToggle nav caret> <div className="d-md-inline">About Us</div></DropdownToggle>
                       <DropdownMenu>
                           <DropdownItem href="/about/us-air-ambulance">US Air Ambulance</DropdownItem>
                           <DropdownItem href="/about/medical-staff">Medical Staff</DropdownItem>
                           <DropdownItem href="/about/past-projects">Past Projects</DropdownItem>
                           <DropdownItem href="/about/past-customers">Testimonials</DropdownItem>
                       </DropdownMenu>
                   </Dropdown>
                  </NavItem>
                  <NavItem>
                    <Dropdown>
                       <DropdownToggle nav caret> <div className="d-md-inline">Transport Options</div></DropdownToggle>
                       <DropdownMenu>
                           <DropdownItem href="/services/air-ambulance">Air Ambulance</DropdownItem>
                           <DropdownItem href="/services/medical-escort">Medical Escort</DropdownItem>
                           <DropdownItem href="/services/long-distance-ground">Long Distance Ground</DropdownItem>
                           <DropdownItem href="/services/commercial-stretcher">Commercial Stretcher</DropdownItem>
                           <DropdownItem href="/services/train-escort">Train Escort</DropdownItem>
                       </DropdownMenu>
                   </Dropdown>
                  </NavItem>
                  <NavItem active={window.location.pathname === '/request-a-quote'}>
                      <NavLink href="/request-a-quote">Request a Quote</NavLink>
                  </NavItem>
                  <NavItem active={window.location.pathname === '/pricing'}>
                      <NavLink href="/pricing">Pricing</NavLink>
                  </NavItem>
                  <NavItem active={window.location.pathname === '/faqs'}>
                      <NavLink href="/faqs">FAQs</NavLink>
                  </NavItem>
                </NavbarNav>
              </Collapse>
            </Navbar>
          </Router>
          </div>
    );
  }
}

export default NavigationComponent

import React from 'react';
import { connect } from 'react-redux'
import Testimonials from '../../components/about/testimonials'

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Testimonials);

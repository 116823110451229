import React from 'react';
import { connect } from 'react-redux'
import PastProjects from '../../components/about/pastProjects'

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PastProjects);

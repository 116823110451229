import React from 'react'
import {Container, Row, Col} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icons from "@fortawesome/fontawesome-free-solid"
import './about.css'

const About = props => (<section className="about" data-stellar-background-ratio="0.5">
  <Container>
    <Row>
      <Col md={6} sm={12}>
        <div className="about-info">
          <div className="wow fadeInUp" data-wow-delay="0.2s">
            <h4>Read our story</h4>
            <h2>We've been helping families bring their loved ones home since 1990 </h2>
          </div>

          <div className="wow fadeInUp" data-wow-delay="0.4s">
            <p>
            We have successfully completed more than 97,000 medical transports of patients since 1990, with a 100% safety and a 98% on time record. For close to 30 years, dozens of families like yours have trusted us every week to ensure the safety of their loved ones as they travel to higher levels of care, or closer to home.  Multiple federal and state government agencies like the State Department and over 600 hospitals have grown to rely on our expertise as well.              <br></br>
              <br></br>
            We have earned an excellent reputation over three decades. Hospital case managers know that no matter which option you choose, domestic or international, ground or air travel, U.S. Air Ambulance is a trusted partner.  They know that our medical and flight professionals will ensure your patient's well-being and that our customer service staff will bring comfort to your family.  Please allow us to be part of your story.
            </p>
        </div>
      </div>
    </Col>

    <Col md={6} sm={12} className="image-wrapper">
      <div className="wow fadeInUp about-image" data-wow-delay="0.6s">
        <div style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
          <FontAwesomeIcon icon={Icons.faStopwatch} />
          <div>
          <h4>30</h4>
          <h6>years of experience</h6>
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
          <FontAwesomeIcon icon={Icons.faSmile} />
          <div>
          <h4>98,000</h4>
          <h6>happy clients</h6>
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
          <FontAwesomeIcon icon={Icons.faHeartbeat} />
          <div>
          <h4>100%</h4>
          <h6>flight saftey record</h6>
          </div>
        </div>
      </div>
    </Col>

  </Row>
</Container> < /section>)

export default About

import React, {Component} from 'react'
import {Container, Row, Col} from 'reactstrap';
import CtaComponent from '../../../containers/callToAction'
import pricingHeaderImage from '../../images/pricing.jpg'
import { Link } from 'react-router-dom'

class Insurance extends Component {
  render() {
    return (<div className="pricing">
      <div className="img-header">
        <h1>Insurance Coverage</h1>
      </div>
      <section>
        <Container>
          <Row>
            <Col md={12}>
              <h3>Will Medicare pay for Air Ambulance Service?</h3>

              <p>In certain cases, Medicare may pay if:</p>
              <ul>
                <li>The medical facility where the patient is located is incapable of treating the patient.</li>
                <li>If there is no facility nearby that can offer the patient the treatment needed.</li>
                <li>If the distance is too far to use ground ambulance service</li>
              </ul>

              <p>Further detailed information is available on our <Link to="understanding-medical-insurance">Medicare guidelines for air ambulance service</Link> page. Although it is difficult to meet these requirements, U.S. Air Ambulance is an approved Medicare provider and has operated a number of Medicare-paid air ambulance trips.</p>
              <h2>Insurance and Air Ambulance Medical Transportation Service</h2>
              <p>There is a chance that your current insurance provider could cover the cost of air ambulance transport. If your insurance company approves the air ambulance trip, we will accept a Letter of Guarantee for payment prior to operating the air ambulance trip.</p>
            </Col>
          </Row>
        </Container>
      </section>
      <CtaComponent></CtaComponent>
    </div>)
  }
}

export default Insurance

import React from 'react';
import { connect } from 'react-redux'
import Insurance from '../../components/pricing/costConsiderations/Insurance.js'

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Insurance);

import { createStore, applyMiddleware, combineReducers } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import createHistory from 'history/createBrowserHistory'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import AirAmbulanceApp from './reducers'
import rootSagas from './sagas'

const sagaMiddleware = createSagaMiddleware()
const loggerMiddleware = createLogger()
const history = createHistory()

const middleware = routerMiddleware(history)

export function configureStore(preloadedState) {
  const store = createStore(
    combineReducers({
      AirAmbulanceApp,
    }),
    preloadedState,
    applyMiddleware(
      thunkMiddleware,
      loggerMiddleware,
      sagaMiddleware,
      ...[middleware]
    )
  )

  sagaMiddleware.run(rootSagas)

  return store
}

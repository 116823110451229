import React, {Component} from 'react'
import {Container, Row, Col} from 'reactstrap';
import CtaComponent from '../../../containers/callToAction'
import crewHeaderImage from '../../images/medical.jpg'

class OurCrew extends Component {
  render() {
    return (<div>
      <div className="img-header">
        <h1>Our Crew</h1>
        <h2> At U.S. Air Ambulance our medical staff is dedicated to providing our patients with safe, secure travel by air ambulance.</h2>
      </div>
      <section className="about-us">
        <Container>
          <Row>
            <Col style={{}} md={12}>

              <p>
                Our medical transport team offers a unique combination of medical expertise that is coupled with in-depth knowledge and experience in flight safety and flight physiology. This distinctive combination of skills is unparalleled in the industry. It makes U.S. Air Ambulance the logical choice for medical air transport.

                <br></br>
                <br></br>
                Candidates applying for a position on our aeromedical team go through a stringent interview process. We review past employment and educational records for each candidate. This review validates that the applicant has achieved appropriate education and certifications, and verifies past employment records, thus ensuring that our patients receive the finest medical care available.

                <br></br>
                <br></br>
                Medical professionals who make up the U.S. Air Ambulance medical team and accompany patients during the medical transport process includes highly skilled, aero-medically competent personnel. They adhere to detailed practice guidelines and are devoted to providing excellent standards of care.

                <br></br>
                <br></br>

                <b>Our staff includes:</b>

                <br></br>
                <br></br>

                <ul>
                  <li>
                    ICU-CCU Certified Registered Flight Nurses
                  </li>
                  <li>
                    ER Flight Nurses
                  </li>
                  <li>
                    Advanced Life Support Critical Care Flight Paramedics
                  </li>
                  <li>
                    Flight RNs
                  </li>
                  <li>
                    Paramedics
                  </li>
                  <li>
                    Flight Respiratory Therapists
                  </li>
                  <li>
                    Anesthesiologists
                  </li>
                  <li>
                    Doctors in critical care medicine
                  </li>
                </ul>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <CtaComponent></CtaComponent>
    </div>)
  }
}

export default OurCrew

import React, { Component } from 'react';
import {Route} from 'react-router-dom'

import NavigationComponent from '../navigation'
import FooterComponent from '../footer'
import Home from '../home'
import About from '../about'
import OurCrew from '../ourCrew'
import PastProjects from '../pastProjects'
import Service from '../services'
import Pricing from '../pricing'
import Insurance from '../insurance'
import UnderstandingInsurance from '../understandingInsurance'
import LowCost from '../lowCost'
import Discount from '../discount'
import Testimonials from '../testimonials'
import FAQ from '../faq'
import RequestAQuote from '../requestAQuote'

import './App.css';
import './animate.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <NavigationComponent/>
        <main>
          <Route exact path="/" component={Home}/>
          <Route exact path="/pricing" component={Pricing}/>
          <Route exact path="/pricing/insurance" component={Insurance}/>
          <Route exact path="/pricing/understanding-medical-insurance" component={UnderstandingInsurance}/>
          <Route exact path="/pricing/discount-options" component={Discount}/>
          <Route exact path="/pricing/low-cost" component={LowCost}/>
          <Route exact path="/about" component={About}/>
          <Route exact path="/about/us-air-ambulance" component={About}/>
          <Route exact path="/about/medical-staff" component={OurCrew}/>
          <Route exact path="/about/past-projects" component={PastProjects}/>
          <Route exact path="/about/past-customers" component={Testimonials}/>
          <Route exact path="/services/:service" component={Service}/>
          <Route exact path="/request-a-quote" component={RequestAQuote}/>
          <Route exact path="/faqs" component={FAQ}/>
        </main>
         <FooterComponent/>
      </div>
    );
  }
}

export default App;

import React, {Component} from 'react'
import {Container, Row, Col} from 'reactstrap';
import CtaComponent from '../../containers/callToAction'
import Quote from '../home/quote'
import './request.css'
import quoteHeaderImage from '../images/quote.jpg'

class RequestAQuote extends Component {
  render() {
    return (<div className="request-a-quote">
      <div className="img-header">
        <h1>Request A Quote</h1>
        <h2> Thank you for your interest in U.S. Air Ambulance. </h2>
      </div>
      <section className="request">
        <Container>
          <Row>
            <Col>
              <p> We are fully qualified to meet any of your medical transport needs and will work with you to determine the method of transport - air, rail, ground or a combination - that fits your time frame, budget and medical conditions. </p>
              <p> Please contact us today to assure the best option at the best price for your unique situation. Our Patient Transport Specialists are personally available 24 hours a day, 365 days a year to answer your phone call , or email us using the form below. </p>
              <p className="phone-number-listing"> <b>Domestic Toll-free </b>: 800-633-5384 </p>
              <p className="phone-number-listing"> <b>International Direct</b>: 001-941-556-6717 </p>
            </Col>
          </Row>
        </Container>
        <Quote></Quote>
      </section>
    </div>)
  }
}

export default RequestAQuote

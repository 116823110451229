import React, { Component } from 'react';
import { connect } from 'react-redux'
import NavigationComponent from '../../components/navigation'

const mapStateToProps = (state, ownProps) => {
  return {
    route: state.AirAmbulanceApp.navigation.route
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavigationComponent);

import React, {Component} from 'react'
import {Container, Row, Col, ListGroup, ListGroupItem} from 'reactstrap';
import CtaComponent from '../../../containers/callToAction'
import './testimonials.css'
import testimonialsHeaderImage from '../../images/testimonials.jpg'

class Testimonials extends Component {
  render() {
    return (<div>
      <div className="img-header">
        <h1>Testimonials</h1>
        <h2> U.S. Air Ambulance has received hundreds of letters of thanks and recommendation from patients and their families. Here are just a few: </h2>
      </div>
      <section className="testimonials">
        <Container>
          <Row>
            <Col md={12}>
              <ListGroup flush="flush">
                <ListGroupItem>
                  <h4>
                    Great Air Ambulance Service
                  </h4>
                  <p>
                    "I just wanted to tell you how much my mother enjoyed the flight even with all of her medical issues. You all provide a wonderful service and, needless to say, we were very pleased. Thank you again so much." - Anne H, Philadelphia, PA
                  </p>
                </ListGroupItem>

                <ListGroupItem>
                  <h4>
                    Thanks to the Medical Flight Team
                  </h4>
                  <p>
                    "My family and I were very pleased with the arrangements made through your service. The team was extremely efficient and professional and, at the same time, very caring and made us all feel very comfortable. My father and mother arrived safe and sound and in stable condition. We couldn't have asked for more. Please relay this message to all involved and thank them for going above and beyond. We felt assured that our parents were in the best of hands." - Kathy, G., Hawaii
                  </p>
                </ListGroupItem>

                <ListGroupItem>
                  <h4>
                    Safe Medical Repatriation
                  </h4>
                  <p>
                    I would like to express my deep appreciation for the swift and safe transportation of my daughter and her son to Houston. We all know what the outcome probably would have been had you not rescued them from the situation they were in, and we will never be able to thank you enough for saving their lives. A special thanks goes to Clif for sticking with me on the telephone all night long, never getting impatient and truly helping me feel like things were going to turn out just fine. To the pilot, co-pilot, physician and paramedic onboard the jet, you truly are heroes. Thanks to your amazing flying skills and medical expertise, Julie and her new son are both doing well. I want everyone involved to know how important you are to our family, and what an impact you have made in our lives. - Peter R. Houston
                  </p>
                </ListGroupItem>

                <ListGroupItem>
                  <h4>
                    Gratitude to the U.S. Air Ambulance Team
                  </h4>
                  <p>
                    "I was faced with the daunting task of bringing my mother home from Barbados. From the moment I first heard your reassuring words, that you could solve my transportation problems in the safe and medically appropriate way that the situation demanded, I stopped feeling panic-stricken. We had contacted other similar ambulance companies, but none offered her the options or level of comfort that sounded to me was standard practice with your company. And none could've been as kind, patient and interested as you were. You surpassed my expectations." - Lynne E., Petersham, MA
                  </p>
                </ListGroupItem>

                <ListGroupItem>
                  <h4>
                    Fast and Safe Medical Charter Flight
                  </h4>
                  <p>
                    "My entire family is very grateful to you and your associates for the first rate job you did in safely transporting my 84-year-old mother who had just broken her hip 600 miles in a total of about four hours. Your advice was always sound and totally in agreement with what others in the medical community were telling us, and your team remained calm, compassionate and competent." - Walter F., Philadelphia, PA
                  </p>
                </ListGroupItem>

                <ListGroupItem>
                  <h4>
                    Successful International Medevac Flight
                  </h4>
                  <p>
                    "My father's illness began almost immediately upon his arrival in Greece. Within a month, he was completely paralyzed from the neck down. To get him home, I called my travel agent, the Red Cross, the State Department and the airlines, but no one could help. I turned to the Yellow Pages and found U.S. Air Ambulance. What a world of difference. The surprise was that you spoke to me in Greek. My family thanks you many times for being there when we needed you." - Anastasia H., Detroit, MI
                  </p>
                </ListGroupItem>

                <ListGroupItem>
                  <h4>
                    Stress-Free Medical Air Transport
                  </h4>
                  <p>
                    "I want to express my gratitude for the wonderful service we received from U.S. Air Ambulance during a critical time. My father recently needed to be transported from Naples, Florida, to the University of Michigan with less than 24 hours notice. My initial call was with Jeff, and then final arrangements were made by Kathy. They were both very helpful. Kathy, with whom I spent a great deal of the evening on the phone, was extremely knowledgeable, accommodating and calming. My parents also praised the kindness and professionalism of both the nurses on board, Michael and Matthew. As we awaited my parent's arrival, we worried that the flight alone had been a great stressor to both. They arrived with smiles on their faces, a calmness that had not been seen or heard for days, and an end to their journey with a big sincere hug from both nurses. Thank you for your professional and compassionate service!" - Angela N., Ann Arbor, MI
                  </p>
                </ListGroupItem>

                <ListGroupItem>
                  <h4>
                    Air Medical Evacuation is "Thorough and Professional"
                  </h4>
                  <p>
                    "Thank you ALL for the impeccable care you took transporting my father from Myrtle Beach to New Haven. From the very first phone call for information to the minute you brought my parents to the hospital in New Haven, your entire operation was thorough, professional, patient and very caring. We are so grateful the ground crew - Jeff, Bryn, Patti and Rachael, and everyone else involved, and to the flight crew led by the critical care nurse, Patrick. It is a huge relief to all of us to have both of them home, with Dad getting the care he needs." - The Rohsberg family, New Haven, CT
                  </p>
                </ListGroupItem>

              </ListGroup>
            </Col>
          </Row>
        </Container>
      </section>
      <CtaComponent></CtaComponent>
    </div>)
  }
}

export default Testimonials

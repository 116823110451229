import React from 'react';
import { connect } from 'react-redux'
import UnderstandingInsurance from '../../components/pricing/costConsiderations/understandingInsurance.js'

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnderstandingInsurance);

import React from 'react'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './accreditation.css'

  // <section id="clients" className="wow fadeInUp">
const Accreditation = props => (
  <section id="clients" className="">
      <OwlCarousel loop dots={false} nav
             responsive={{
                     0:{ items: 3, },
                     600:{ items: 5, slideBy: 3 },
                 }}
                 autoplay
                 autoplayTimeout={2000}
                 autoplayHoverPause
        className="clients-carousel">
        <img src="img/clients/1.png" alt=""></img>
        <img src="img/clients/2.gif" alt=""></img>
        <img src="img/clients/3.png" alt=""></img>
        <img src="img/clients/4.png" alt=""></img>
        <img src="img/clients/5.jpeg" alt=""></img>
        <img src="img/clients/6.png" alt=""></img>
        <img src="img/clients/7.png" alt=""></img>
        <img src="img/clients/8.png" alt=""></img>
        <img src="img/clients/9.jpg" alt=""></img>
        <img src="img/clients/10.jpg" alt=""></img>
        <img src="img/clients/11.jpg" alt=""></img>
        <img src="img/clients/12.png" alt=""></img>
        <img src="img/clients/13.jpg" alt=""></img>
      </OwlCarousel>
  </section>
)

export default Accreditation

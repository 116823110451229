import React, { Component } from 'react'

import { WOW } from 'wowjs'
import { Container } from 'reactstrap';
import Hero from './hero'
import Quote from './quote'
import About from './about'
import Steps from './steps'
import Services from './services'
import Accreditation from './accreditation'
import Testimonials from './testimonials'
import CallToAction from './cta'

class HomeComponent extends Component {

  componentDidMount() {
    new WOW().init();
  }

  render() {
    return(
      <div>
        <Hero></Hero>
        <Quote></Quote>
        <Container>
          <Services></Services>
          <Steps></Steps>
        </Container>
        <CallToAction> </CallToAction>
        <Container>
          <Testimonials></Testimonials>
          <About></About>
        </Container>
        <Accreditation></Accreditation>
      </div>
    )
  }

}

export default HomeComponent

import React from 'react'

import {Container, Row, Col} from 'reactstrap';
import './steps.css'

const Steps = props => (<section className="steps">
  <Container>
    <Row>
      <Col md={12}>
      <h2 >How it works</h2>
      </Col>
      <Col md={3}>
        <h5>01.</h5>
        <h6>Call our patient consultant to discuss your needs </h6>
        <p> Every patient is unique. Our consultant will listen to your circumstance and will provide you multiple options including costs, travel times and medical capabilities to help you make the right decision for you.
        </p>
      </Col>
      <Col md={3}>
        <h5>02.</h5>
        <h6>Review transport options with your loved ones</h6>
        <p>We will give you a written quote including all options available to your patient that includes all the information needed to help your family make an informed decision.</p>
      </Col>
      <Col md={3}>
        <h5>03.</h5>
        <h6>When you are ready, we will manage every detail of your trip</h6>
        <p>Our team will coordinate with facilities, medical staffs, ground transportation, airport authorities, and your family to schedule, prepare for and perform your trip. You will get a written itinerary and we're available 24/7 to quickly respond to any questions or concerns you have.</p>
      </Col>
      <Col md={3}>
        <h5>04.</h5>
        <h6>We perform a safe, stress-free, on-schedule patient transfer</h6>
        <p>100% safety and 98% on time record plus attention to details and customer focus means your patient will experience a stress free, "bed to bed” transfer service, critically important to long term patient health</p>
      </Col>
    </Row>
  </Container>
</section>)

export default Steps
